export function flattenKeys(object: any, initialPathPrefix = ""): any {
  if (!object || typeof object !== "object") {
    return [{ [initialPathPrefix]: object }];
  }

  const prefix = initialPathPrefix
    ? Array.isArray(object)
      ? initialPathPrefix
      : `${initialPathPrefix}.`
    : "";

  return Object.keys(object)
    .flatMap((key) =>
      flattenKeys(
        object[key],
        Array.isArray(object) ? `${prefix}[${key}]` : `${prefix}${key}`
      )
    )
    .reduce((acc, path) => ({ ...acc, ...path }));
}

export function flattenObjectFilters(ob: any) {
  const toReturn: any = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;
    if (typeof ob[i] == "object" && ob[i] !== null) {
      const flatObject = flattenObjectFilters(ob[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        console.log(i);
        if ([i].includes("review_groups")) {
          console.log(flatObject[x]);
          toReturn[i] = flatObject[x].toLowerCase();
        } else {
          toReturn[i + "." + x] = flatObject[x].toLowerCase();
        }
      }
    } else {
      toReturn[i] = ob[i].toString().toLowerCase();
    }
  }
  return toReturn;
}

function flattenObject(ob: any): any {
  const toReturn: any = {};

  if (Array.isArray(ob)) {
    ob.forEach((item, index) => {
      const flatItem = flattenObject(item);
      for (const key in flatItem) {
        if (flatItem.hasOwnProperty(key)) {
          toReturn[index + "." + key] = flatItem[key];
        }
      }
    });
  } else {
    for (const i in ob) {
      if (!ob.hasOwnProperty(i)) continue;
      if (
        Array.isArray(ob[i]) &&
        ob[i].every((item: any) => typeof item === "string")
      ) {
        toReturn[i] = ob[i].join(", ");
      } else if (typeof ob[i] == "object" && ob[i] !== null) {
        const flatObject = flattenObject(ob[i]);
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;
          toReturn[i + "." + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
  }
}

export function FlattenObjectWithGroups<T>(array: T[]): any {
  const groupedObjets: any = {};

  const flatObject = flattenObject(array);

  for (const key in flatObject) {
    if (flatObject.hasOwnProperty(key)) {
      const groupPrefix = key.split(".")[0];
      const subkey = key.substring(groupPrefix.length + 1);
      if (!groupedObjets[groupPrefix]) {
        groupedObjets[groupPrefix] = {};
      }
      groupedObjets[groupPrefix][subkey] = flatObject[key];
    }
  }

  return Object.values(groupedObjets);
}
