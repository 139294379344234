import Avatar from "@atoms/avatar/avatar";
import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { PageBlock } from "@atoms/layout/page-block";
import { Info, SectionSmall } from "@atoms/text";
import { useCustomer } from "@features/customers/state/use-customer";
import { useCustomerBy } from "@features/customers/state/use-customer-by";
import { KytTransactionParticipant } from "@features/kyt/types";
import { ROUTES } from "@features/routes";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import CustomerTransactionsChart from "@views/client/customers/customer-details/transaction-chart";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function TransactionParticipant(props: {
  id?: string | null;
  participant: KytTransactionParticipant;
  direction: "from" | "to";
  onShowMore: (more: boolean) => void;
  full?: boolean;
  className?: string;
}) {
  const { customer: CustomerBy } = useCustomerBy({
    external_ids: props.participant.external_id || "",
  });
  const customerData = CustomerBy.data;

  const { customer, refresh } = useCustomer(customerData?.id || "");

  const identityData = [
    {
      label: "First names",
      value: customerData?.first_name,
    },
    {
      label: "Last names",
      value: customerData?.last_name,
    },
    {
      label: "Dates of birth",
      value:
        customerData?.date_of_birth &&
        new Date(customerData.date_of_birth).toISOString().split("T")[0],
    },
    {
      label: "Domicile",
      value: customerData?.domicile_code,
    },
    {
      label: "Nationality",
      value: customerData?.nationality_code,
    },
  ];
  const deviceData = [
    {
      label: "User Agent",
      value: props.participant.device?.user_agent,
    },
    {
      label: "IP",
      value: props.participant.device?.ip,
    },
    {
      label: "Location",
      value: props.participant.device?.geolocation,
    },
    {
      label: "Location",
      value: props.participant.device?.geolocation,
    },
    {
      label: "Session ID",
      value: props.participant.device?.session_id,
    },
    {
      label: "Session duration",
      value: props.participant.device?.session_duration,
    },
    {
      label: "3DS",
      value: props.participant.device?.["3ds"],
    },
    {
      label: "2FA",
      value: props.participant.device?.["2fa"],
    },
  ];
  const paymentInstitution = [
    {
      label: "Code",
      value: props.participant.payment_institution?.code,
    },
    {
      label: "Name",
      value: props.participant.payment_institution?.name,
    },
    {
      label: "Country",
      value: props.participant.payment_institution?.country,
    },
  ];
  const paymentMethod = [
    {
      label: "Type",
      value: props.participant.payment_method?.type,
    },
    {
      label: "Code",
      value: props.participant.payment_method?.code,
    },
    {
      label: "Country",
      value: props.participant.payment_method?.country,
    },
  ];

  useEffect(() => {
    if (props?.id) {
      refresh();
    }
  }, [props?.id, refresh]);

  return (
    <div className="flex flex-col gap-4">
      <PageBlock className={props.className}>
        <div className="flex flex-row">
          <SectionSmall className="mb-2">
            Transaction {props.direction === "from" ? "source" : "target"}
          </SectionSmall>
          <div className="grow" />
          <Button
            size="sm"
            theme="outlined"
            onClick={() => props.onShowMore(!props.full)}
            shortcut={["e"]}
            className="rounded-r-none -mr-px"
          >
            {!props.full ? "Show more" : "Show less"}
          </Button>
          <Link
            className={!props.id ? "pointer-events-none" : ""}
            to={ROUTES.CustomerView.replace(":id", props.id || "")}
          >
            <Button
              size="sm"
              theme="primary"
              className="rounded-l-none -ml-px"
              disabled={!props.id}
            >
              Open customer
            </Button>
          </Link>
        </div>
        <div className="flex flex-row space-x-2">
          {props.participant.account_type === "N" && (
            <Avatar className="shrink-0" size={14} />
          )}
          {props.participant.account_type === "F" && (
            <BuildingOfficeIcon className="h-8 w-8" />
          )}
          <div className="grid grid-col-3 md:grid-col-5 gap-2 w-full">
            <div className="col-span-3 md:col-span-5">
              <Info>Identity (ID : {props.participant.external_id})</Info>
              <hr />
            </div>
            {identityData.map((items, index: number) => (
              <InputLabel
                key={index}
                label={items.label}
                input={items.value || "-"}
              />
            ))}
          </div>
        </div>
        {props.full && (
          <div className="w-full">
            <Info>
              Devices ({props.participant.device?.fingerprint || "-"})
            </Info>
            <hr />
            <div className="grid grid-cols-5 gap-2">
              {deviceData.map((item, index: number) => (
                <InputLabel
                  key={index}
                  label={item.label}
                  input={item.value || "-"}
                />
              ))}
            </div>
            <Info>
              Payment institution (
              {props.participant.payment_institution?.external_id || "-"})
            </Info>
            <hr />
            <div className="grid grid-cols-5 gap-2">
              {paymentInstitution.map((item, index: number) => (
                <InputLabel
                  key={index}
                  label={item.label}
                  input={item.value || "-"}
                />
              ))}
            </div>

            <Info>Payment method</Info>
            <hr />
            <div className="grid grid-cols-5 gap-2">
              {paymentMethod.map((item, index: number) => (
                <InputLabel
                  key={index}
                  label={item.label}
                  input={item.value || "-"}
                />
              ))}
            </div>
          </div>
        )}
        <div className="h-4" />
      </PageBlock>
      {customer && (
        <PageBlock className="mb-2 !py-3">
          <CustomerTransactionsChart customer={customer} />
        </PageBlock>
      )}
    </div>
  );
}
