import { DropdownButton, OptionsType } from "@atoms/dropdown";
import { useCustomer } from "@features/customers/state/use-customer";
import { ROUTES } from "@features/routes";
import {
  CameraIcon,
  NewspaperIcon,
  PaperAirplaneIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { InboxModalAtom } from "@views/client/inbox/modal";
import { useSetRecoilState } from "recoil";
import { useHasAccess } from "../../../../features/auth/state/use-access";
import { twMerge } from "tailwind-merge";
import { useEffect } from "react";

export const SharedProductsMenu = ({
  id,
  additionalOptions,
  className,
}: {
  id: string;
  additionalOptions?: OptionsType[];
  className?: string;
}) => {
  const hasAccess = useHasAccess();
  const { customer, refresh } = useCustomer(id || "");

  const openCustomerInbox = useSetRecoilState(InboxModalAtom);

  useEffect(() => {
    refresh();
  }, [id, refresh]);

  return (
    <DropdownButton
      options={[
        ...(additionalOptions || []),
        ...(hasAccess("CUSTOMER_READ")
          ? [
              {
                to: ROUTES.CustomerView.replace(":id", id),
                icon: (p: any) => <UserIcon {...p} />,
                label: "Customer details",
              },
            ]
          : []),
        ...(hasAccess("CHAT")
          ? [
              {
                onClick: () => {
                  openCustomerInbox(customer?.details.customer.id || null);
                },
                icon: (p: any) => <PaperAirplaneIcon {...p} />,
                label: "Customer inbox",
              },
            ]
          : []),
        {
          type: "divider",
          label: "",
        },
        ...(hasAccess("SIDENAV_ALERT")
          ? [
              {
                to:
                  ROUTES.Alerts +
                  "?id=" +
                  customer?.details?.customer?.external_id,
                icon: (p: any) => <ShieldCheckIcon {...p} />,
                label: "Go!Scan alerts",
              },
            ]
          : []),
        ...(hasAccess("PRESS")
          ? [
              {
                to: ROUTES.PressReportView.replace(":customer_id", id),
                icon: (p: any) => <NewspaperIcon {...p} />,
                label: "Go!Press report",
              },
            ]
          : []),
        ...(hasAccess("SIDENAV_SESSION")
          ? [
              {
                to:
                  ROUTES.Sessions +
                  "?external_id=" +
                  customer?.details?.customer?.external_id,
                icon: (p: any) => <CameraIcon {...p} />,
                label: "Go!Vid sessions",
              },
            ]
          : []),
      ]}
      theme="default"
      size="sm"
      className={twMerge("float-right mt-4", className)}
    >
      Menu
    </DropdownButton>
  );
};
