import { DoughnutChart } from "@components/dognut-chart";
import { useDashboard } from "@features/dashboard/state/hooks";
import { formatNumber } from "@features/utils";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PRESS_REPORT_STATUS_COUNT_NAMES = {
  no_status_count: "No status",
  pending_count: "Pending",
  action_required_count: "Action required",
};

export const PRESS_REPORT_STATUS_COUNT_COLORS = {
  no_status_count: "#6b7280",
  pending_count: "#3b82f6",
  action_required_count: "#f97316",
};

export const PRESS_REPORT_RISK_COUNT_NAMES = {
  none_count: "None",
  low_count: "Low",
  medium_count: "Medium",
  high_count: "High",
};

export const PRESS_REPORT_RISK_COUNT_COLORS = {
  none_count: "#64748b",
  low_count: "#eab308",
  medium_count: "#f97316",
  high_count: "#ef4444",
};

export const PressChart = () => {
  const { press } = useDashboard();

  const pressInfo = Object.keys(press?.reports_by_edd_status || {})
    .map((a) => ({
      label: a,
      value: (press?.reports_by_edd_status || ({} as any))[a] as number,
    }))
    .filter((a) => a.value > 0);

  const pressInfoAssignment = Object.keys(
    press?.assigned_reports_by_edd_status || {}
  )
    .map((a) => ({
      label: a,
      value: (press?.assigned_reports_by_edd_status || ({} as any))[
        a
      ] as number,
    }))
    .filter((a) => a.value > 0);

  return (
    <div className="w-80 h-40">
      <DoughnutChart
        options={{
          animations: false as any,
          plugins: {
            legend: {
              position: "right",
              labels: {
                usePointStyle: true,
              },
            },
          },
          maintainAspectRatio: false,
        }}
        data={{
          labels: pressInfo.map(
            (a) =>
              `${
                (PRESS_REPORT_STATUS_COUNT_NAMES as any)[a.label] || "Unknown"
              }: ${formatNumber(a.value)}`
          ),
          datasets: [
            ...(!pressInfo.length
              ? [
                  {
                    label: "No data",
                    backgroundColor: ["rgba(220,220,220,1.0)"],
                    data: [1],
                  },
                ]
              : []),
            {
              data: pressInfo.map((a) => a.value),
              backgroundColor: pressInfo.map(
                (a) =>
                  (PRESS_REPORT_STATUS_COUNT_COLORS as any)[a.label] ||
                  "#000000"
              ),
            },
            {
              label: "Assigned",
              data: pressInfoAssignment.map((a) => a.value),
              backgroundColor: pressInfoAssignment.map(
                (a) =>
                  (PRESS_REPORT_STATUS_COUNT_COLORS as any)[a.label] ||
                  "#000000"
              ),
            },
          ],
        }}
      />
    </div>
  );
};
