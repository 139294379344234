import { Alert } from "@atoms/alert";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { SessionsApiClient } from "@features/sessions/api-client/api-client";
import { useSessionDecisions } from "@features/sessions/state/use-session-decisions";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { atom, useRecoilState } from "recoil";

export const SessionChangeExpirationDateModalAtom = atom<{
  open: boolean;
  comment: string;
  date: Date | null;
  documentDate: Date | null;
  session_id: string;
}>({
  key: "SessionDecisionChangeDecisionModalAtom",
  default: {
    open: false,
    comment: "",
    date: null,
    session_id: "",
    documentDate: null,
  },
});

export default function SessionChangeExpirationDateModal() {
  const [modal, setModal] = useRecoilState(
    SessionChangeExpirationDateModalAtom
  );
  useEffect(() => {
    if (modal.open) {
      setModal({ ...modal });
    }
  }, [modal.open]);
  return (
    <Modal
      open={modal.open}
      onClose={() =>
        setModal({
          ...modal,
          open: false,
          comment: "",
          date: null,
          session_id: "",
          documentDate: null,
        })
      }
    >
      {modal.open && <SessionChangeExpirationDateModalContent />}
    </Modal>
  );
}

const SessionChangeExpirationDateModalContent = () => {
  const [modal, setModal] = useRecoilState(
    SessionChangeExpirationDateModalAtom
  );
  const { refresh } = useSessionDecisions(modal.session_id);
  const [loading, setLoading] = useState(false);
  const now = new Date();
  const oneYear = modal.documentDate ? new Date(
    modal.documentDate.getFullYear() + 1,
    modal.documentDate.getMonth(),
    modal.documentDate.getDate()
  ) : null;

  return (
    <ModalContent title="Add Decision">
      <InputLabel
        label="Detected document date"
        input={
          <InputDate size="sm" value={modal.documentDate} disabled={true} />
        }
      />

      <InputLabel
        label="Date"
        input={
          <div className="flex items-center justify-center gap-1">
            <InputDate
              size="sm"
              className="flex-1"
              value={modal.date}
              onChange={(value) => {
                setModal({ ...modal, date: value });
              }}
            />
            <Button
              size={"sm"}
              className="py-0.5 h-max"
              onClick={() => setModal({ ...modal, date: modal.documentDate })}
            >
              Reset
            </Button>
          </div>
        }
      />
      <InputLabel
        className="mt-4"
        label="Comment"
        input={
          <Input
            placeholder="Comment"
            multiline
            size="sm"
            value={modal.comment}
            onChange={(e) => {
              setModal({ ...modal, comment: e.target.value });
            }}
          />
        }
      />
      <div className="mt-4">
        {modal.date && modal.date.getTime() != modal.documentDate?.getTime() && modal.date < now && (
          <Alert
            icon={ExclamationTriangleIcon}
            theme="warning"
            className="rounded-sm"
            title="The selected date is already expired. Do you want to continue ?"
          ></Alert>
        )}

        {modal.date && oneYear && modal.date > oneYear && (
          <Alert
            icon={ExclamationTriangleIcon}
            theme="warning"
            className="rounded-sm"
            title="The selected date is far away from document original date. Do you want to continue ?"
          ></Alert>
        )}
      </div>

      <ButtonConfirm
        confirmTitle="Save Session Decision"
        className="mt-6 float-right"
        theme="primary"
        disabled={!modal.date || !modal.session_id || !modal.comment}
        loading={loading}
        onClick={async () => {
          if (!modal.date) {
            return;
          }
          try {
            setLoading(true);
            await SessionsApiClient.changeSessionExpirationDate(
              modal.session_id,
              modal.date,
              modal.comment
            );
            toast.success("Session Decision added successfully");
            setModal({
              ...modal,
              open: false,
              date: null,
              comment: "",
              session_id: "",
              documentDate: null,
            });
          } catch (error) {
            toast.error("Error adding Session Decision");
            setModal({ ...modal, open: false });
          } finally {
            refresh();
            setLoading(false);
          }
        }}
      >
        Save
      </ButtonConfirm>
    </ModalContent>
  );
};
