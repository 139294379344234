import Avatar from "@atoms/avatar/avatar";
import { Button } from "@atoms/button/button";
import { GetStartedCard } from "@atoms/card/get-started";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { BaseSmall, Info, Section, Title } from "@atoms/text";
import { Table } from "@components/table";
import { useAlertsPostFilters } from "@features/alerts-filters/state/use-alerts-filters";
import { PostFilteringAliasType } from "@features/alerts-filters/types";
import { useHasAccess } from "@features/auth/state/use-access";
import { ROUTES } from "@features/routes";
import { formatTime } from "@features/utils/dates";
import { Page } from "@atoms/layout/page";
import _ from "lodash";
import { useState } from "react";
import { Link } from "react-router-dom";
import { CpuChipIcon, PlusIcon } from "@heroicons/react/24/outline";

export const PostFilteringListPage = () => {
  const { filters, loading, save, refresh } = useAlertsPostFilters();
  const [createModal, setCreateModal] = useState(false);
  const [createdFilterName, setCreatedFilterName] = useState("");
  const [createdFilterModel, setCreatedFilterModel] = useState("");
  const hasAccess = useHasAccess();

  return (
    <Page>
      <Modal open={createModal} onClose={() => setCreateModal(false)}>
        <ModalContent title="Create new filter">
          <InputLabel
            label="Label"
            input={
              <Input
                placeholder="site-1-filter"
                value={createdFilterName}
                onChange={(e) =>
                  setCreatedFilterName(
                    e.target.value
                      .toLocaleLowerCase()
                      .replace(/[^a-z0-9-]/g, "-")
                  )
                }
              />
            }
          />
          <InputLabel
            className="mt-4"
            label="Template"
            input={
              <Select onChange={(e) => setCreatedFilterModel(e.target.value)}>
                {filters.map((f) => (
                  <option key={f.name} value={f.name || ""}>
                    {f.name || "default"}
                  </option>
                ))}
              </Select>
            }
          />

          <Button
            loading={loading}
            disabled={
              createdFilterName.length === 0 ||
              createdFilterName === "default" ||
              filters.map((a) => a.name).includes(createdFilterName)
            }
            className="mt-4"
            onClick={async () => {
              if (
                await save(
                  createdFilterName,
                  filters.find((a) => a.name === createdFilterModel)?.settings!
                )
              ) {
                setCreateModal(false);
              }
            }}
          >
            Create
          </Button>
        </ModalContent>
      </Modal>

      <Title>Screening filtering</Title>

      <div className="my-4">
        <GetStartedCard
          show={filters.length === 1 && !loading}
          title={<>Screening filtering</>}
          text={
            <>
              You can customize the default screening filter to reduce false
              positive during the ongoing daily screening. You can also create
              custom filters to be used in the single-identity screening API or
              the Name Search single-identity search feature.
            </>
          }
        />
      </div>

      <Section>Filter configuration selector</Section>

      <Info>
        You can define which filter configuration will be applied for each
        customer.
      </Info>
      <div className="mt-2" />

      {hasAccess("POST_FILTERING_UPDATE") && (
        <Link to={ROUTES.ScanDecisionTree}>
          <Button theme="primary" size="sm">
            <CpuChipIcon className="w-4 h-4 mr-2" />
            Open filter selector
          </Button>
        </Link>
      )}

      <div className="my-6 border-t" />

      {hasAccess("POST_FILTERING_UPDATE") && (
        <>
          <Button
            onClick={() => {
              setCreatedFilterName("");
              setCreatedFilterModel(filters[0].name);
              setCreateModal(true);
            }}
            className="float-right"
            theme="primary"
            size="sm"
          >
            <PlusIcon className="w-4 h-4 mr-2" />
            Add filter
          </Button>
        </>
      )}
      <Section>Filters configurations</Section>

      <Table
        loading={loading}
        showPagination={false}
        columns={[
          {
            title: "Label",
            render: (filter) => <>{filter.name || "default"}</>,
          },
          {
            title: "Last edited",
            thClassName: "w-72",
            render: (filter) => (
              <div className="flex items-center">
                <div className="inline-block mr-1">
                  <Avatar
                    size={4}
                    fallback={filter.settings["default_settings"].agent_name}
                  />
                </div>
                <BaseSmall>
                  {filter.settings["default_settings"].agent_name}
                </BaseSmall>
                <Info className="ml-2">
                  {formatTime(filter.settings["default_settings"].edit_date)}
                </Info>
              </div>
            ),
          },
          ...(hasAccess("ALERT_CASEMANAGEMENT")
            ? [
                {
                  title: "Actions",
                  thClassName: "w-24",
                  className: "text-right",
                  headClassName: "justify-end",
                  render: (filter: PostFilteringAliasType) => (
                    <div>
                      <Link
                        to={ROUTES.PostFilteringView.replace(
                          ":id",
                          filter.name || "default"
                        )}
                      >
                        <Button theme="outlined" size="sm">
                          {hasAccess("POST_FILTERING_UPDATE") ? "Edit" : "Open"}
                        </Button>
                      </Link>
                    </div>
                  ),
                },
              ]
            : []),
        ]}
        data={_.sortBy(filters, (filter) => filter.name)}
        onRequestData={() => refresh()}
      />
    </Page>
  );
};
