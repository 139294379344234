import { RestSearchQuery } from "@components/search-bar/utils/types";
import { useFetch } from "@features/utils";
import _ from "lodash";
import {
  CustomerDetailType,
  CustomerEddHistoryType,
  CustomerRelationships,
  CustomerRiskFactorsType,
  CustomerSearchField,
  CustomerSearchType,
  UpdateCustomersRequest,
} from "../types";
import { CUSTOMERS_RISKS_BACKEND_NAME } from "../utils";

export class CustomersApiClient {
  static getCustomersSearchSchema = async () => {
    const response = await useFetch(`/web/customers/schema`, {
      method: "GET",
    });
    const data = await response.json();
    return data.searchable_fields as CustomerSearchField[];
  };

  static getCustomersAdvanced = async (
    query: RestSearchQuery[] | null,
    options: Partial<{
      limit: number;
      offset: number;
      order_by: string;
      order_way: "ASC" | "DESC";
    }>
  ) => {
    const response = await useFetch(`/web/customers`, {
      method: "POST",
      body: JSON.stringify({
        query,
        options,
      }),
    });
    const data = await response.json();
    data.data = (data.data || []).map((customer: any) => {
      return {
        ...customer,
        customer_id: customer.id,
        concat_name: customer.full_names?.[0],
        first_name: customer.first_names?.[0],
        last_name: customer.last_names?.[0],
        middle_name: customer.middle_names?.[0],
        trading_name: customer.trading_names?.[0],
        company_name: customer.company_names?.[0],
        account_type: customer.account_type === "F" ? 3 : 2,
        risk:
          Object.keys(CUSTOMERS_RISKS_BACKEND_NAME).find(
            (a) =>
              CUSTOMERS_RISKS_BACKEND_NAME[a as any] === customer.overall_risk
          ) || 1,
      } as CustomerSearchType;
    });
    return increaseKytData(
      data as { data: CustomerSearchType[]; total: number }
    );
  };

  static getCustomersState = async () => {
    const response = await useFetch(`/web/customers/states`, {
      method: "GET",
    });
    const data = await response.json();
    return data as { [key: number]: string };
  };

  static getCustomersRelated = async (id: string) => {
    const response = await useFetch(`/web/customers/${id}/related`, {
      method: "GET",
    });
    const data = await response.json();
    return data as CustomerRelationships;
  };

  static getCustomerDetails = async (id: string) => {
    const response = await useFetch(`/web/customer/detail`, {
      method: "POST",
      formData: true,
      body: JSON.stringify({
        customer_id: id,
      }),
    });
    const data = await response.json();
    return data as CustomerDetailType;
  };

  static getCustomerRiskFactors = async (id: string) => {
    const response = await useFetch(`/web/customer/detail/get_risk_factors`, {
      method: "POST",
      formData: true,
      body: JSON.stringify({
        customer_id: id,
        query: JSON.stringify({ pagingForm: { perPage: 100 } }),
      }),
    });
    const data = await response.json();
    return data as CustomerRiskFactorsType;
  };

  static getCustomerEddHistory = async (id: string) => {
    const response = await useFetch(`/web/customer/detail/get_edd_history`, {
      method: "POST",
      formData: true,
      body: JSON.stringify({
        customer_id: id,
        query: JSON.stringify({
          orderForm: { orderBy: "created", orderWay: "desc" },
          pagingForm: { onPage: 1, perPage: 100 },
        }),
      }),
    });
    const data = await response.json();
    return data as CustomerEddHistoryType;
  };

  static updateCustomerStatus = async (
    id: string,
    state: number,
    comment: string,
    customNextReviewDate?: Date
  ) => {
    await useFetch(`/web/customer/detail/save`, {
      method: "POST",
      body: JSON.stringify({
        customer_id: id,
        data: JSON.stringify({
          eddComment: comment,
          eddState: state,
          nextReviewDate: customNextReviewDate
            ? Math.floor(customNextReviewDate.getTime() / 1000)
            : undefined,
        }),
      }),
      formData: true,
    });
  };

  static updateCustomer = async (updates: UpdateCustomersRequest) => {
    updates.customers = updates.customers.map((customer) => {
      return {
        ...customer,
        fields: _.mapValues(customer.fields, (v) => `${v}`),
      };
    });
    const response = await useFetch(`/web/customers/save`, {
      method: "POST",
      body: JSON.stringify(updates),
    });
    const data = await response.json();
    return data as {
      added: { customer_id: string; external_id: string }[];
      detail: any[];
      status: "success" | "fail";
      verify_input: boolean;
    };
  };

  static getRevisions = async (id: string) => {
    const response = await useFetch(`/web/customers/${id}/revisions`, {
      method: "GET",
    });
    const data = await response.json();
    return data as CustomerDetailType["customer"][];
  };
}

// Add the missing "all" keys not stored on elastic search for storage size reasons
// We can always rebuild them on the fly
const increaseKytData = (data: {
  data: CustomerSearchType[];
  total: number;
}) => {
  data.data = data.data.map((customer) => {
    // Add the "all" key to the kyt_data object
    if (customer.kyt_data) {
      for (const context in customer.kyt_data) {
        if (customer.kyt_data[context]?.all) {
          continue;
        }
        customer.kyt_data[context] = customer.kyt_data[context] || {};
        customer.kyt_data[context].all = Object.keys(customer.kyt_data).filter(key => !(["in", "out"].includes(key))).reduce(
          (acc, value: string) => {
            let tmp = _.cloneDeep(acc);
            (["in", "out"] as ("in" | "out")[]).forEach((dir) => {
              Object.keys(customer.kyt_data[dir] || {}).forEach((key) => {
                tmp = tmp || ({} as any);
                tmp[dir] = tmp[dir] || ({} as any);
                tmp[dir][key] = tmp[dir][key] || 0;
                if (tmp?.[dir]?.[key]) {
                  tmp[dir][key] += customer.kyt_data[value][dir][key];
                } else {
                  tmp[dir][key] = customer.kyt_data?.[value]?.[dir]?.[key] || 0;
                }
              });
            });
            return tmp;
          },
          {} as any
        );
      }
    }

    // Add the "all" context now
    if (!customer.kyt_data.all) {
      customer.kyt_data.all = Object.keys(customer.kyt_data).filter(key => !(["in", "out"].includes(key))).reduce(
        (acc, value: string) => {
          let tmp = _.cloneDeep(acc);
          (["in", "out"] as ("in" | "out")[]).forEach((dir) => {
            Object.keys(customer.kyt_data[value][dir]).forEach((key) => {
              tmp = tmp || ({} as any);
              tmp[dir] = tmp[dir] || ({} as any);
              tmp[dir][key] = tmp[dir][key] || 0;
              if (tmp?.[dir]?.[key]) {
                tmp[dir][key] += customer.kyt_data[value][dir][key];
              } else {
                tmp[dir][key] = customer.kyt_data[value][dir][key];
              }
            });
          });
          return tmp;
        },
        {} as any
      );
    }

    return customer;
  });
  return data;
};
