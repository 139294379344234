import { Base, SectionSmall } from "@atoms/text";
import { Table } from "@components/table";
import _ from "lodash";
import { useMemo } from "react";

type TransactionFieldsProps = {
  fields: { [key: string]: string };
};

export function TransactionFields({ fields }: TransactionFieldsProps) {
  const fieldArray = useMemo((): { name: string; value: any }[] => {
    const data = Object.keys(fields).map((key: string) => ({
      name: key,
      value: fields[key],
    }));
    return data;
  }, [fields]);
  return (
    <div>
      <hr className="my-4 -mx-4" />
      <SectionSmall>{"Other fields"}</SectionSmall>
      <div className="mt-2" />
      <Table
        data={_.sortBy(fieldArray, (f) => f.name)}
        columns={[
          {
            thClassName: "w-1/2",
            render: (row) => <Base className="capitalize">{row.name}</Base>,
          },
          {
            render: (row) => <Base>{row.value}</Base>,
          },
        ]}
        showPagination={false}
      />
      <div className="mt-2" />
    </div>
  );
}
