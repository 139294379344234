import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { defaultInputClassName, Input } from "@atoms/input/input-text";
import Link from "@atoms/link";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { Info } from "@atoms/text";
import { inboxAvailableTemplateLanguages } from "@features/inbox-templates/enum";
import { useInboxTemplates } from "@features/inbox-templates/state/use-inbox-templates";
import { ReactNode, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useRecoilState } from "recoil";
import { InboxTemplateModalAtom } from ".";
import "./index.css";
import { useTemplateVariables } from "@features/inbox-templates/state/use-template-variables";

export const InboxTemplateModal = () => {
  const { loading, editInboxTemplate, createInboxTemplate } =
    useInboxTemplates();
  const [languageModal, setLanguageModal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [modal, setModal] = useRecoilState(InboxTemplateModalAtom);
  const templateVariables = useTemplateVariables();

  return (
    <>
      <Modal open={languageModal} onClose={() => setLanguageModal(false)}>
        <ModalContent title="Translate template">
          {Object.keys(modal.content || {}).map((lang) => (
            <div className="flex flex-row mb-2" key={lang}>
              <div className="grow">
                {(inboxAvailableTemplateLanguages as any)[lang] || lang}
              </div>
              <div>
                <ButtonConfirm
                  confirmTitle="Delete translation"
                  confirmMessage="You will lost this translation"
                  theme="danger"
                  disabled={Object.keys(modal.content || {}).length <= 1}
                  size="sm"
                  onClick={() => {
                    const newContent = { ...modal.content };
                    const newSubject = { ...modal.subject };
                    delete newContent[lang];
                    delete newSubject[lang];
                    setModal({
                      ...modal,
                      content: newContent,
                      subject: newSubject,
                    });
                    setCurrentLanguage(Object.keys(newContent)[0]);
                    setLanguageModal(false);
                  }}
                >
                  Delete
                </ButtonConfirm>
                <Button
                  className="ml-2"
                  theme="default"
                  size="sm"
                  onClick={() => {
                    setCurrentLanguage(lang);
                    setLanguageModal(false);
                  }}
                >
                  Edit
                </Button>
              </div>
            </div>
          ))}

          <Select
            className="mt-4"
            value=""
            onChange={(e) => {
              const newContent = { ...modal.content };
              const newSubject = { ...modal.subject };
              newContent[e.target.value] =
                modal.content?.[currentLanguage] || "";
              newSubject[e.target.value] =
                modal.subject?.[currentLanguage] || "";
              setModal({ ...modal, content: newContent, subject: newSubject });
              setCurrentLanguage(e.target.value);
              setLanguageModal(false);
              e.target.selectedIndex = 0;
            }}
          >
            <option disabled value="">
              Add translation
            </option>
            {Object.keys(inboxAvailableTemplateLanguages)
              .filter((l) => !Object.keys(modal.content || {}).includes(l))
              .map((lang) => (
                <option key={lang} value={lang}>
                  {(inboxAvailableTemplateLanguages as any)[lang]}
                </option>
              ))}
          </Select>
        </ModalContent>
      </Modal>
      <Modal
        className="min-w-[60vw]"
        open={modal.open}
        onClose={() => setModal({ open: false, type: "ADD" })}
      >
        <ModalContent title="Create inbox template">
          <InputLabel
            label="Label"
            className="mb-4"
            input={
              <Input
                placeholder="Name of your template"
                type="text"
                value={modal.label}
                onChange={(e) => setModal({ ...modal, label: e.target.value })}
              />
            }
          />

          <InputLabel
            label="Use as"
            input={
              <Select
                value={
                  modal.use_as_signature
                    ? "signature"
                    : modal.use_as_receipt
                    ? "receipt"
                    : "template"
                }
                onChange={(e) => {
                  setModal({
                    ...modal,
                    use_as_signature: e.target.value === "signature",
                    use_as_receipt: e.target.value === "receipt",
                  });
                }}
              >
                <option value={"template"}>Template</option>
                <option value={"signature"}>Signature</option>
                <option value={"receipt"}>Receipt</option>
              </Select>
            }
          />

          <hr className="my-6 -mx-6" />

          <div className="relative">
            <div className="absolute -top-1 right-1">
              <Info>
                {(inboxAvailableTemplateLanguages as any)[currentLanguage] ||
                  currentLanguage}{" "}
                version -{" "}
                <Link onClick={() => setLanguageModal(true)}>Change</Link>
              </Info>
            </div>

            <InputLabel
              label="Subject and body"
              className="mt-4"
              input={
                <div className="flex flex-col group">
                  <TwigInput
                    disabled={modal.use_as_signature}
                    placeholder="Subject"
                    singleLine
                    className={
                      "subject focus:z-10 -mb-px border-b-none rounded-b-none"
                    }
                    value={
                      modal.use_as_signature
                        ? "-"
                        : modal.subject?.[currentLanguage] || ""
                    }
                    onChange={(e: any) =>
                      setModal({
                        ...modal,
                        subject: {
                          ...modal.subject,
                          [currentLanguage]: e.target.value,
                        },
                      })
                    }
                    variables={modal.use_as_receipt ? [] : templateVariables}
                  />
                  {modal.use_as_signature && (
                    <Input
                      multiline
                      disabled
                      value={`Hello M. Doe,
You'll find my signature below.
`}
                      className="-mb-px rounded-none"
                    />
                  )}
                  <TwigInput
                    placeholder="Template body goes here, use {{variable}} to insert variables."
                    className={
                      "border-t-none rounded-t-none " +
                      (modal.use_as_receipt
                        ? " -mb-px border-b-none rounded-b-none "
                        : "")
                    }
                    value={modal.content?.[currentLanguage] || ""}
                    onChange={(e: any) => {
                      setModal({
                        ...modal,
                        content: {
                          ...modal.content,
                          [currentLanguage]: e.target.value,
                        },
                      });
                    }}
                    variables={modal.use_as_receipt ? [] : templateVariables}
                  />
                  {modal.use_as_receipt && (
                    <Input
                      multiline
                      disabled
                      value={`------------------
Message receipt #123456789...`}
                      className="rounded-t-none -z-10"
                    />
                  )}
                </div>
              }
            />
          </div>

          <Info>
            You can use variables by starting like {"{{agent.full_name}}"},{" "}
            <Link target="_blank" href="docs/gochat/templates">
              read more
            </Link>
            .
          </Info>

          <ButtonConfirm
            confirmTitle="Save template"
            className="mt-6 float-right"
            theme="primary"
            loading={loading}
            disabled={!modal.content}
            onClick={async () => {
              if (modal.type === "EDIT") {
                await editInboxTemplate(modal.id!, {
                  enabled: modal.enabled!,
                  label: modal.label!,
                  subject: modal.subject!,
                  content: modal.content!,
                  use_as_signature: modal.use_as_signature!,
                  use_as_receipt: modal.use_as_receipt!,
                });
              } else {
                await createInboxTemplate({
                  enabled: modal.enabled ?? true,
                  label: modal.label ?? "",
                  subject: modal.subject ?? {},
                  content: modal.content ?? {},
                  use_as_signature: modal.use_as_signature ?? false,
                  use_as_receipt: modal.use_as_receipt ?? false,
                });
              }
              setModal({ open: false, type: "ADD" });
            }}
          >
            Save
          </ButtonConfirm>
        </ModalContent>
      </Modal>
    </>
  );
};

const TwigInput = (props: {
  singleLine?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  variables?: string[];
  value?: string;
  onChange: (e: any) => void;
}) => {
  return (
    <MentionsInput
      disabled={props.disabled}
      placeholder={props.placeholder}
      singleLine={props.singleLine}
      className={
        "mentions-input " + props.className + " " + defaultInputClassName()
      }
      type="text"
      value={props.value?.replace(
        /(^|[^{])\{%([^%]+)%}([^}]|$)/g,
        "$1{{%$2%}}$3"
      )}
      suggestionsPortalHost={document.body}
      customSuggestionsContainer={(c: ReactNode) => (
        <div className="rounded-sm bg-white rounded-md shadow-sm p-2">{c}</div>
      )}
      onChange={(e: any) => {
        e.target.value = e.target.value.replace(/\{\{%([^%]+)%}}/g, "{%$1%}");
        props.onChange(e);
      }}
    >
      <Mention
        className="text-blue-500 bg-blue-500"
        style={{
          backgroundColor: "#2563eb44",
          borderRadius: "0.25rem",
        }}
        regex={/{{([^}\n]+)}}/}
        markup="{{__id__}}"
        displayTransform={(id: string) => `{{${id}}}`}
        trigger={/(({((\{|%)[^}%\n]*((}|%)(})?)?)?))$/}
        data={Object.keys(props.variables || []).map((key) => ({
          id: key,
          display: `{{${key}}}`,
        }))}
      />
    </MentionsInput>
  );
};
