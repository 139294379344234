import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, Section } from "@atoms/text";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import { MessageLabelType } from "@features/messages-labels/types";
import { stringToColor } from "@features/utils";
import { useEffect, useState } from "react";

export const MessagesLabelsTables = () => {
  const { labels, save, loading, refresh } = useMessagesLabels();
  const [modal, setModal] = useState<
    Partial<MessageLabelType> & { open: boolean }
  >({ open: false });
  const hasAccess = useHasAccess();

  useEffect(() => {
    if (labels.length === 0) {
      refresh();
    }
  }, [labels.length, refresh]);

  const columns: Column<MessageLabelType>[] = [
    {
      title: "Code",
      render: (row) => (
        <Base className="flex items-center mr-2">{row.code}</Base>
      ),
    },
    {
      title: "Name",
      render: (row) => (
        <>
          <Tag
            noColor
            className="capitalize"
            style={{ backgroundColor: stringToColor(row.code) }}
          >
            {row.name}
          </Tag>
        </>
      ),
    },
    {
      title: "Actions",
      headClassName: "justify-end",
      className: "justify-end",
      thClassName: "w-24",
      render: (row) =>
        hasAccess("RISK_SETTINGS_WRITE") &&
        !row.is_default && (
          <Button
            theme="outlined"
            size="sm"
            onClick={() => setModal({ open: true, ...row })}
          >
            Edit
          </Button>
        ),
    },
  ];

  return (
    <>
      <Modal open={modal.open} onClose={() => setModal({ open: false })}>
        <ModalContent title="Add/Edit Message tag">
          <InputLabel
            label="Label"
            input={
              <Input
                placeholder="Identity Message"
                value={modal.name || ""}
                onChange={(e) =>
                  setModal({
                    ...modal,
                    name: e.target.value,
                    ...(modal.code !==
                    (modal.name || "")
                      .toLocaleLowerCase()
                      .replace(/[^a-z0-9]+/g, "_")
                      ? {}
                      : {
                          code: e.target.value
                            .toLocaleLowerCase()
                            .replace(/[^a-z0-9]+/g, "_"),
                        }),
                  })
                }
              />
            }
          />

          <InputLabel
            className="mt-4"
            label="Unique identifier"
            input={
              <Input
                placeholder="identity_Message"
                value={modal.code || ""}
                onChange={(e) => setModal({ ...modal, code: e.target.value })}
              />
            }
          />

          <ButtonConfirm
            confirmTitle="Save Message tag"
            className="mt-6 float-right"
            theme="primary"
            loading={loading}
            disabled={
              !modal.name ||
              !modal.code ||
              labels.some((f) => f.id !== modal.id && f.code === modal.code)
            }
            onClick={async () => {
              if (
                await save([
                  ...labels.filter((f) => f.id !== modal.id),
                  {
                    id: modal.id,
                    code: modal.code || "",
                    name: modal.name || "",
                    is_default: false,
                    disabled: false,
                  },
                ])
              ) {
                setModal({ ...modal, open: false });
              }
            }}
          >
            Save
          </ButtonConfirm>

          {labels.find((f) => f.id === modal.id) && (
            <ButtonConfirm
              confirmTitle="Delete Message label"
              confirmMessage="This will be applied now and Messages will not be searchable using this label anymore"
              className="mt-6"
              theme="danger"
              loading={loading}
              onClick={async () => {
                if (await save(labels.filter((f) => f.code !== modal.code))) {
                  setModal({ ...modal, open: false });
                }
              }}
            >
              Remove
            </ButtonConfirm>
          )}
        </ModalContent>
      </Modal>

      {hasAccess("CUSTOMER_UPDATE") && (
        <Button
          className="float-right"
          size="sm"
          onClick={() =>
            setModal({
              open: true,
            })
          }
        >
          Create tag
        </Button>
      )}
      <Section>Messages tags</Section>

      <Table
        data={labels}
        columns={columns}
        showPagination={false}
        loading={loading}
      />
    </>
  );
};
