import { Button } from "@atoms/button/button";
import { Section } from "@atoms/text";
import { useWebAuthn } from "@features/webauthn/state/useWebAuthn";
import toast from "react-hot-toast";
import { AuthenticationDevices } from "./authentication-devices";
import { useAuth } from "@features/auth/state/hooks";
import { useCallback, useState } from "react";
import ModalTotp from "./modal-totp";

export function Security() {
  const { user } = useAuth();
  const [isOpenModalTwoFa, setOpenModalTwoFa] = useState(false);

  // WebAuthn
  const { useRegisterMutation, useDeleteDeviceMutation, getDevicesQuery } =
    useWebAuthn();
  const { mutateAsync: register, isLoading: isRegisteringWebAuthn } =
    useRegisterMutation();
  const { mutateAsync: deleteDevice, isLoading: isDeletingDevice } =
    useDeleteDeviceMutation();
  const { data: devices, refetch, isFetching } = getDevicesQuery();
  const onWebAuthnRegister = async () => {
    try {
      await register();
      await refetch();
    } catch (e: any) {
      toast.error(
        "An error has occurred while registering your device, please try again later"
      );
    }
  };

  const onDelete = useCallback(
    async (credentialId: number) => {
      if (
        devices &&
        devices.length <= 1 &&
        user?.clients.some((client) => client.two_fa_required)
      ) {
        toast.error(
          "You cannot delete this device because at least one of your clients needs to have a TOTP  authenticator. Please add another one before deleting this one.",
          { duration: 6000 }
        );
        return;
      }
      try {
        await deleteDevice(credentialId);
        await refetch();
      } catch (e: any) {
        toast.error(
          "An error has occurred while deleting your device, please try again later"
        );
      }
    },
    [user, devices, refetch, deleteDevice]
  );

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row items-center justify-between my-2">
          <Section className="my-auto">Authenticate</Section>
          <div className="flex gap-4">
            <Button
              onClick={onWebAuthnRegister}
              loading={isRegisteringWebAuthn}
              theme="primary"
              size="sm"
            >
              New Access Key
            </Button>
            <Button
              onClick={() => setOpenModalTwoFa(true)}
              theme="primary"
              size="sm"
            >
              New Auth App
            </Button>
          </div>
        </div>
        <AuthenticationDevices
          loading={isFetching || isDeletingDevice || isRegisteringWebAuthn}
          data={devices || []}
          onDelete={onDelete}
          refetch={refetch}
        />
        {user?.email_login && (
          <ModalTotp
            emailLogin={user.email_login}
            isOpen={isOpenModalTwoFa}
            setOpen={setOpenModalTwoFa}
            refetch={refetch}
          />
        )}
      </div>
    </>
  );
}
