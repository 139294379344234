import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Base, InfoSmall, Section } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { useSessionDecisions } from "@features/sessions/state/use-session-decisions";
import { useSessionStates } from "@features/sessions/state/use-sessions-states";
import { formatTime } from "@features/utils/dates";
import { CodeBracketIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { SessionDecisionAddModalAtom } from "./decision-add-modal";
import Avatar from "@atoms/avatar/avatar";
import { useEffect } from "react";
import { twMerge } from "tailwind-merge";
import { LABEL_TYPE_COLORS } from "@features/sessions/utils";

export const SessionDecisionsTimeline = ({
  session_id,
}: {
  session_id: string;
}) => {
  const { states } = useSessionStates();
  const { decisions, refresh } = useSessionDecisions(session_id);
  const [modal, setModal] = useRecoilState(SessionDecisionAddModalAtom);
  const hasAccess = useHasAccess();

  useEffect(() => {
    if (session_id) {
      refresh();
    }
  }, [session_id, refresh]);

  return (
    <>
      <Section className="mt-4 mb-4 w-max-2xl">
        Decisions
        {hasAccess("ALERT_CASEMANAGEMENT") && (
          <Button
            onClick={() =>
              setModal({
                ...modal,
                status: decisions[0]?.status_id || 0,
                open: true,
                session_id: session_id,
              })
            }
            className="float-right"
            theme="outlined"
            size="sm"
            shortcut={["u"]}
          >
            Add Decision
          </Button>
        )}
      </Section>
      <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-3">
        {_.sortBy(decisions, (d) => -new Date(d.date).getTime()).map(
          (decision, index) => (
            <li
              key={index}
              className={
                "mb-2 ml-6 transition-opacity " +
                (index === 0 ? "" : " opacity-75 hover:opacity-100 ")
              }
            >
              <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-slate-500 rounded-full">
                {!decision.agent_id && (
                  <CodeBracketIcon className="h-4 w-4 text-white" />
                )}
                {!!decision.agent_id && (
                  <Avatar size={6} fallback={decision.agent_name} />
                )}
              </span>
              {index === 0 && (
                <Tag
                  noColor
                  className={twMerge(
                    "bg-blue-500 text-white capitalize block",
                    (LABEL_TYPE_COLORS as any)[
                      states.find((s) => s.id === decision.status_id)
                        ?.type as any
                    ]
                  )}
                >
                  {states.find((s) => s.id === decision.status_id)?.label ||
                    "Unknown"}
                </Tag>
              )}
              {index > 0 && (
                <Tag className="capitalize block">
                  {states.find((s) => s.id === decision.status_id)?.label ||
                    "Unknown"}
                </Tag>
              )}
              <Base className="whitespace-pre-wrap">{decision.comment}</Base>
              <InfoSmall className="block mt-1">
                {decision.agent_name || "System"} • {formatTime(decision.date)}
              </InfoSmall>
            </li>
          )
        )}
      </ol>
    </>
  );
};
