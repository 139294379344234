import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { Info, Title } from "@atoms/text";
import { AssigneeTag } from "@components/assignations/tag";
import { Table } from "@components/table";
import { useAssignments } from "@features/assignments/state/use-assignment";
import { ROUTES } from "@features/routes";
import { SCENARIOS_LANGUAGES } from "@features/scenarios/utils";
import { SessionsFiltersAtom } from "@features/sessions/state/store";
import { useSessions } from "@features/sessions/state/use-sessions";
import { useSessionStates } from "@features/sessions/state/use-sessions-states";
import { extractCustomerSessionFullName } from "@features/sessions/utils";
import { useControlledEffect } from "@features/utils";
import { formatTime } from "@features/utils/dates";

import _ from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import SessionScore from "./components/session-score";
import SessionWarning from "./components/session-warning";
import SessionsListFilters from "./filters";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { Pagination } from "@components/table/table";
import { useHasAccess } from "@features/auth/state/use-access";

export default function SessionsListPage() {
  const { sessions, fetchSessions, refresh, loading } = useSessions();
  useAssignments("session", sessions.data?.map((a) => a.session_id) || []);

  const [filters, setFilters] = useRecoilState(SessionsFiltersAtom);
  const { states, refresh: refreshStates } = useSessionStates();
  const { scenarios } = useScenarios();

  const hasAccess = useHasAccess();

  //Load url params if exists
  const [loadedUrlParams, setLoadedUrlParams] = useState(false);
  useControlledEffect(() => {
    setFilters({
      ...filters,
      ...Object.fromEntries(new URLSearchParams(window.location.search)),
    });
    setLoadedUrlParams(true);
  }, []);

  useEffect(() => {
    if (loadedUrlParams) refresh(filters);
  }, [filters, refresh, loadedUrlParams]);

  useEffect(() => {
    if (states.length === 0) refreshStates();
  }, [refreshStates, states.length]);

  return (
    <Page>
      <Title>Sessions</Title>
      <div className="mt-4" />
      {loadedUrlParams && (
        <SessionsListFilters
          value={filters}
          onChange={(e) => {
            setFilters({
              ...filters,
              ..._.omit(e, "order_by", "order_way", "per_page", "on_page"),
              on_page: 1,
            });
          }}
        />
      )}
      <br />
      <Table
        options={{
          exportCsv: hasAccess("SCENARIO_UPDATE")
            ? {
                fetchData: async (params: Pagination) => {
                  const result = await fetchSessions({
                    ...filters,
                    on_page: params.page,
                    per_page: params.perPage,
                  });
                  return result.data;
                },
              }
            : {},
        }}
        page={filters.on_page}
        total={sessions.total || 0}
        initialPagination={{
          page: 1,
          perPage: filters.per_page,
          orderBy: [
            "external_id",
            "name",
            "date",
            "",
            "",
            "score",
            "",
            "",
          ].indexOf(filters.order_by as string),
          order: filters.order_way,
        }}
        data={sessions.data || []}
        columns={[
          {
            title: "#",
            className: "",
            thClassName: "w-40",
            orderable: true,
            render: (row) => (
              <Info className="overflow-hidden text-ellipsis whitespace-nowrap w-32">
                <span data-tooltip={row.external_id}>{row.external_id}</span>
              </Info>
            ),
          },
          {
            title: "Name",
            orderable: true,
            render: (row) => (
              <div className="flex items-center">
                {extractCustomerSessionFullName(row)}
              </div>
            ),
          },
          {
            title: "Date",
            thClassName: "w-40",
            orderable: true,
            render: (row) => (
              <div className="flex items-center flex-row capitalize whitespace-nowrap">
                {
                  <Info className="text-sm">
                    {formatTime(row.start_timestamp)}
                  </Info>
                }
              </div>
            ),
          },
          {
            title: "Scenario",
            thClassName: "w-40",
            render: (row) => (
              <Info>
                {row.scenario_ref} (
                {SCENARIOS_LANGUAGES[row.language] || row.language})
              </Info>
            ),
          },
          {
            title: "Category",
            thClassName: "w-40",
            render: (row) => {
              const scenario = scenarios?.data.find(
                (scenario) => scenario.code === row.scenario_ref
              );
              if (!scenario) return <Info>-</Info>;
              return <Tag>{scenario.result_label || "-"}</Tag>;
            },
          },
          {
            title: "Score",
            className: "overflow-hidden text-ellipsis text-right justify-end",
            thClassName: "w-20",
            orderable: true,
            render: (row) => {
              return (
                <SessionScore
                  text={row.score !== null ? row.score.toFixed(0) + " %" : "-"}
                  status={
                    row.score === null
                      ? "slate"
                      : row.fail
                      ? "red"
                      : row.success
                      ? "green"
                      : "yellow"
                  }
                />
              );
            },
          },
          {
            title: "Flags",
            thClassName: "w-32",
            render: (row) => (
              <div className="">
                {row.warnings?.map((warning) => (
                  <span className="-mx-1" key={warning}>
                    <SessionWarning warning={warning}></SessionWarning>
                  </span>
                ))}
              </div>
            ),
          },
          {
            title: "Status",
            thClassName: "w-32",
            render: (row) => {
              const type = states.find((s) => s.label === row.status)?.type;
              if (row.score === null) {
                return (
                  <Tag noColor className={"text-white bg-slate-500"}>
                    Unfinished
                  </Tag>
                );
              }
              return (
                states.length > 0 && (
                  <Tag
                    noColor
                    className={
                      "text-white " +
                      (type === "NEGATIVE"
                        ? "bg-red-500"
                        : type === "POSITIVE"
                        ? "bg-green-500"
                        : "bg-yellow-500")
                    }
                  >
                    {row.status}
                  </Tag>
                )
              );
            },
          },
          {
            title: "",
            className: "text-right",
            headClassName: "justify-end",
            thClassName: "w-24",
            render: (row) => (
              <>
                <AssigneeTag type="session" id={row.session_id} />
              </>
            ),
          },
          {
            title: "Actions",
            className: "text-right",
            headClassName: "justify-end",
            thClassName: "w-20",
            render: (row) => (
              <Link to={ROUTES.SessionView.replace(":id", row.session_id)}>
                <Button size="sm" theme="outlined">
                  View
                </Button>
              </Link>
            ),
          },
        ]}
        onRequestData={async (pagination) => {
          setFilters({
            ...filters,
            on_page: pagination.page,
            per_page: pagination.perPage,
            order_by:
              ["external_id", "name", "date", "", "", "score", "", ""][
                pagination.orderBy!
              ] || null,
            order_way: pagination.order || "ASC",
          });
        }}
        loading={loading}
      />
    </Page>
  );
}
