import {
  AgentType,
  AuthState,
  AuthType,
  LoginStep,
} from "@features/auth/state/store";
import { ROUTES } from "@features/routes";
import {
  LoadingState,
  flushGlobalEffects,
  useGlobalEffect,
} from "@features/utils";
import * as Sentry from "@sentry/browser";
import jwt_decode from "jwt-decode";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilCallback, useRecoilState } from "recoil";
import { AuthApiClient } from "../api-client/api-client";
import { AuthJWT } from "../jwt";
import { isEmbed } from "@features/utils/use-embed-utils";
import { TwoFAChallengeData, TwoFAType, NeedTwoFAError } from "../types";
import { client } from "@passwordless-id/webauthn";
import toast from "react-hot-toast";

let autoRenewTimeout: any = 0;

export let logoutFromService = () => {
  // No-op
};

export const useAuth = () => {
  const [auth, setAuth] = useRecoilState(AuthState);
  const [loading, setLoading] = useRecoilState(LoadingState("useAuth"));
  const navigate = useNavigate();

  useGlobalEffect(
    "SentryConfiguration",
    () => {
      Sentry.configureScope(function (scope) {
        scope.setTag("client", auth.agent?.client_code || "");
        scope.setTag("client_id", auth.agent?.clients[0]?.id || "");
        scope.setUser({
          id: `${auth.agent?.id}`,
          email: auth.agent?.email_login || "",
          client: auth.agent?.client_code || "",
          client_id: auth.agent?.clients[0]?.id || "",
        });
      });
    },
    [
      auth.agent?.id,
      auth.agent?.email_login,
      auth.agent?.client_code,
      auth.agent?.clients,
    ]
  );

  useGlobalEffect(
    "useAuth",
    () => {
      setLoading(true);
    },
    []
  );

  const getExtractedToken = () => {
    if (!AuthJWT.token) return null;
    return jwt_decode(AuthJWT.token) as {
      id: string;
      lang: string;
      role: string;
      mfa: {
        method: string;
        exp: number;
      }[];
      ip: string;
      created_at: number;
      req_id: string;
      sandbox: boolean;
      iat: number;
      exp: number;
    };
  };

  const refreshUser = async () => {
    let customer: AgentType = {
      id: 1,
      name: "",
      email_login: "",
      avatar: "",
      client_code: "",
      permissions: [],
      clients: [],
    };
    let needs2FA = false;
    if (!isEmbed()) {
      try {
        customer = await AuthApiClient.getUser();
        customer.permissions = await AuthApiClient.getAcos();
      } catch (e: any) {
        if (e instanceof NeedTwoFAError) {
          needs2FA = true;
        } else {
          AuthJWT.empty();
          logout();
          toast.error(
            "You currently do not have access to the application. Please contact your administrator if you think it is a mistake."
          );
          return;
        }
      }
    }

    setAuth({
      ...auth,
      isLoggedIn: true,
      loginStep: LoginStep.LoggedIn,
      authorization: AuthJWT.token || "",
      authorizationRefresh: AuthJWT.refresh || "",
      agent: customer,
      needs2FA,
    });

    // ALG-249
    // Set Current Client Id in AuthJWT to access it in other places (POST Requests)
    if (customer.clients.length > 0) {
      // if client id is set and is one of the customer clients, dont change
      if (
        !AuthJWT.clientId ||
        !customer.clients.find((c) => c.id === AuthJWT.clientId && c.active)
      ) {
        const preferedClient = localStorage.getItem("prefered_client");
        AuthJWT.clientId =
          customer.clients.find((c) => `${c.id}` === preferedClient && c.active)
            ?.id || customer.clients[0].id;
      }
    } else {
      AuthJWT.clientId = null;
    }
    if (needs2FA) {
      const urlParams = new URLSearchParams(window.location.search);
      const redirect = urlParams.get("r");
      const selectServer = window.location.search
        .toLocaleLowerCase()
        .includes("selectserver");
      const pathname = window.location.pathname;
      navigate(
        `${ROUTES.Setup2FA}?r=${encodeURIComponent(
          redirect ||
            (pathname.startsWith("/login") ? ROUTES.Dashboard : pathname)
        )}${selectServer ? "&selectServer=1" : ""}`
      );
    }

    return customer;
  };

  const autoRenew = (refreshToken: string, expireAt: number) => {
    clearTimeout(autoRenewTimeout);
    autoRenewTimeout = setTimeout(async () => {
      refresh(refreshToken);
    }, (expireAt * 1000 - Date.now()) / 2);
  };

  const refresh = async (refreshToken: string) => {
    const {
      access_token: token,
      refresh_token,
      expires_at,
    } = await AuthApiClient.refresh(refreshToken || AuthJWT.token || "");
    if (!token) {
      logout();
      return false;
    }
    AuthJWT.token = token;
    AuthJWT.refresh = refresh_token;
    // parse local storage for current client id
    AuthJWT.clientId = JSON.parse(
      localStorage.getItem("agent.current_client") || "null"
    );
    autoRenew(refresh_token, expires_at);

    await refreshUser();
  };

  const get2FAChallenge = async (
    type: TwoFAType,
    email: string
  ): Promise<TwoFAChallengeData> => {
    const { challenge, credential_ids } = await AuthApiClient.get2FAChallenge(
      type,
      email
    );

    if (type === TwoFAType.WebAuthn) {
      const authentication = await client.authenticate({
        challenge,
        allowCredentials: credential_ids.map((id) => ({ id })),
      });
      return authentication;
    }

    throw new Error("2FA not allowed");
  };

  const verif2FAUserCode = (values: string, limit: number) => {
    return values.trim().length === limit;
  };

  const login = async (email: string, password: string) => {
    const {
      access_token: token,
      refresh_token,
      expires_at,
      credentials,
    } = await AuthApiClient.login(email, password);

    if (credentials && credentials.length > 0) {
      const listTypes = credentials.map((e) => e.type); // [0, 1, 1]  /  WebAuthn = 0, Totp = 1
      const simpleListTypes = new Set(listTypes); // [0, 1]
      if (simpleListTypes.size >= 2) {
        setAuth({
          ...auth,
          loginStep: LoginStep.AllTwoFA,
        });
      } else {
        if (simpleListTypes.has(0)) {
          setAuth({
            ...auth,
            loginStep: LoginStep.Webauthn,
          });
        } else if (simpleListTypes.has(1)) {
          setAuth({
            ...auth,
            loginStep: LoginStep.Totp,
          });
        }
      }

      return null;
    }

    if (!token || !refresh_token || !expires_at) {
      logout();
      return false;
    }
    AuthJWT.token = token;
    AuthJWT.refresh = refresh_token;
    autoRenew(refresh_token, expires_at);

    await refreshUser();
    return true;
  };

  const twoFALogin = async (
    email: string,
    password: string,
    type: TwoFAType,
    data: TwoFAChallengeData
  ) => {
    const {
      access_token: token,
      refresh_token,
      expires_at,
    } = await AuthApiClient.twoFALogin(email, password, type, data);

    if (!token || !refresh_token || !expires_at) {
      logout();
      return false;
    }
    AuthJWT.token = token;
    AuthJWT.refresh = refresh_token;
    autoRenew(refresh_token, expires_at);

    await refreshUser();
    return true;
  };

  const renewAuthorization = useRecoilCallback(
    () => async () => refresh(AuthJWT.refresh || ""),
    []
  );
  const getUser = renewAuthorization;

  const logout = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        if (AuthJWT.token) AuthApiClient.logout();
        AuthJWT.empty();
        const updated = {
          ...snapshot.getLoadable(AuthState).contents,
          isLoggedIn: false,
          loginStep: LoginStep.NotLoggedIn,
          authorization: null,
          authorizationRefresh: null,
          agent: null,
        };
        setAuth(updated);
        saveAuth(updated);
        flushGlobalEffects();

        const urlParams = new URLSearchParams(window.location.search);
        const redirect = urlParams.get("r");
        const selectServer = window.location.search
          .toLocaleLowerCase()
          .includes("selectserver");
        const pathname = window.location.pathname;
        navigate(
          ROUTES.Login +
            "?r=" +
            encodeURIComponent(
              redirect ||
                (pathname.startsWith("/login") ? ROUTES.Dashboard : pathname)
            ) +
            (selectServer ? "&selectServer=1" : "")
        );
      },
    []
  );

  const switchCurrentClient = useRecoilCallback(
    () => async (clientId: number) => {
      localStorage.setItem("agent.current_client", JSON.stringify(clientId));
      localStorage.setItem("prefered_client", JSON.stringify(clientId));
      window.location.reload();
    },
    []
  );

  logoutFromService = logout;

  const saveAuth = useCallback(
    (updated?: AuthType) => {
      localStorage.setItem(
        "agent.access_token",
        JSON.stringify(updated ? updated?.authorization : auth?.authorization)
      );
      localStorage.setItem(
        "agent.refresh_token",
        JSON.stringify(
          updated ? updated?.authorizationRefresh : auth?.authorizationRefresh
        )
      );
      localStorage.setItem(
        "agent.current_client",
        JSON.stringify(AuthJWT.clientId)
      );
      localStorage.setItem(
        "agent.profile",
        JSON.stringify(updated ? updated?.agent : auth?.agent)
      );
    },
    [auth?.agent, auth?.authorizationRefresh, auth?.authorization]
  );

  useEffect(() => {
    if (auth.isLoggedIn) setLoading(false);
  }, [auth.isLoggedIn, setLoading]);

  useGlobalEffect(
    "useAuthLogin",
    () => {
      if (!auth.isLoggedIn) {
        if (auth.authorization) {
          AuthJWT.token = auth.authorization;
          AuthJWT.refresh = auth.authorizationRefresh;
          renewAuthorization().then(() => {
            setLoading(false);
          });
        } else {
          logout();
          setLoading(false);
        }
        return () => {
          // No-op
        };
      }

      saveAuth();
      setLoading(false);
    },
    [auth.isLoggedIn]
  );

  const user = auth.agent;
  const needs2FA = !!auth.needs2FA;

  return {
    loginStep: auth.loginStep,
    loading,
    user,
    agent: user?.clients.find((c) => c.id === AuthJWT.clientId),
    clientId: AuthJWT.clientId,
    language: "en",
    logout,
    login,
    refreshToken: refresh,
    refreshUser,
    getUser,
    switchCurrentClient,
    getExtractedToken,
    get2FAChallenge,
    verif2FAUserCode,
    twoFALogin,
    needs2FA,
  };
};
