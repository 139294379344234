import { is2XX, useFetch } from "@features/utils";
import { TotpRegisterResponse } from "../types";
import { UseQueryResult } from "react-query";

export class TotpApiClient {
  static getTotpGenerateQrCode = async (
    emailLogin?: string
  ): Promise<UseQueryResult<TotpRegisterResponse>> => {
    const response = await useFetch(
      `/web/authentication/totp/new?email=${emailLogin}`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    if (!is2XX(response.status)) {
      throw new Error(
        "We were unable to generate your 2FA QR code at this time."
      );
    }
    return data;
  };

  static newTotpVerify = async (props: {
    userCode: string;
    secret?: string;
  }) => {
    const response = await useFetch(`/web/authentication/totp/check_secret`, {
      method: "POST",
      body: JSON.stringify(props),
    });
    return response;
  };
}
