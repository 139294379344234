import { is2XX, useFetch } from "@features/utils";
import {
  CreateTransactionInput,
  CustomerTransactionsAggregate,
  CustomerTransactionsAggregateRequestOptions,
  KytEdge,
  KytGraph,
  KytNode,
  KytPostHocPattern,
  KytPostHocPatternRequestOptions,
  KytTransaction,
  KytTransactionHistory,
  KytTransactionsRequestOptions,
} from "../types";
import { KYT_TRANSACTION_STATES } from "../utils";
import { flattenObjectFilters } from "@features/utils/flatten";

export class KytApiClient {
  static getTransactions = async (
    options: Partial<KytTransactionsRequestOptions>
  ) => {
    const filters = {
      external_id: null,

      after: null,
      before: null,

      amount_equals: null,
      converted_amount_equals: null,
      amount_gte: 0,
      converted_amount_gte: null,
      amount_lte: null,
      converted_amount_lte: null,

      crypto_blockchain: null,
      crypto_tx_hash: null,

      currency: null,

      customer: null,
      customers: [],
      from_customer: null,
      from_institution: null,
      to_customer: null,
      to_institution: null,

      on_page: 1,
      per_page: 10,

      review_groups: [],
      state: null,

      comment: null,
      tags: [],
      ...options,
    };

    // handle currency "converted"
    if (filters.currency === "converted") {
      filters.currency = null;
      filters.converted_amount_gte = filters.amount_gte;
      filters.converted_amount_lte = filters.amount_lte;
      filters.converted_amount_equals = filters.amount_equals;
      filters.amount_gte = null;
      filters.amount_lte = null;
      filters.amount_equals = null;
    }

    const specialFields: (keyof KytTransactionsRequestOptions)[] = [
      "review_groups",
      "crypto_blockchain",
      "currency",
      "state",
    ];

    let objectList = {};
    if (filters) {
      for (const key of Object.keys(filters)) {
        for (const items of specialFields) {
          if (items === key && filters[key] !== null) {
            objectList = { ...objectList, [key]: filters[key] };
          }
        }
      }
    }
    const res = flattenObjectFilters(objectList);
    const flatRes = Object.keys(res).map((e: any) => `${e}:${res[e]}`);

    const response = await useFetch(`/web/kyt/transactions`, {
      method: "POST",
      body: JSON.stringify({ ...filters, tags: flatRes }),
    });
    const data = await response.json();
    return data as { data: KytTransaction[]; total: number };
  };

  static getTransactionsSQLQuery = async (query: string) => {
    const response = await useFetch(`/web/kyt/transactions/query/sql`, {
      method: "POST",
      body: JSON.stringify({
        query,
      }),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to get kyt transactions SQL query");
    }
    const data = await response.json();

    if (!data.results) {
      return {
        transactions: [],
        total: 0,
      };
    }

    return {
      transactions: data.results.map(
        (item: any) => item.transaction
      ) as KytTransaction[],
      total: data.results.length,
    };
  };

  static createTransaction = async (options: CreateTransactionInput) => {
    const response = await useFetch(`/web/kyt/transaction`, {
      method: "POST",
      body: JSON.stringify(options),
    });
    const data = await response.json();
    return data as KytTransaction;
  };

  static getTransaction = async (id: string) => {
    const response = await useFetch(`/web/kyt/transaction/${id}`, {
      method: "GET",
    });
    const data = await response.json();
    return data as KytTransaction;
  };

  static getTransactionFullHistory = async (id: string) => {
    const response = await useFetch(`/web/kyt/transaction/${id}/full_history`, {
      method: "GET",
    });
    const data = await response.json();
    return data as KytTransactionHistory;
  };

  static updateTransaction = async (
    id: string,
    state: number,
    comment: string
  ) => {
    const response = await useFetch(`/web/kyt/transaction/${id}`, {
      method: "POST",
      body: JSON.stringify({
        state: KYT_TRANSACTION_STATES[state],
        comment,
      }),
    });
    const data = await response.json();
    return data as KytTransaction;
  };

  static getTransactionsCypherQuery = async (query?: string) => {
    const graph = {
      nodes: [] as KytNode[],
      edges: [] as KytEdge[],
    } as KytGraph;

    if (!query) {
      return graph;
    }

    const response = await useFetch(`/web/kyt/transactions/query/cypher`, {
      method: "POST",
      body: JSON.stringify({
        query,
        // query:
        // "MATCH (a:Node)-[e:TRANSACTION_ALL]->(b:Node)-[e:TRANSACTION_ALL]->(c:Node)-[e:TRANSACTION_ALL]->(a:Node) WHERE a <> b AND b <> c AND a <> c RETURN a, b, e, c;",
        // query: "MATCH (a:Node)-[e:TRANSACTION_ALL]->(b:Node) RETURN a, e, b;",
        // query: "MATCH (a:Node) RETURN a;",
      }),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to get kyt graph ");
    }
    const data = await response.json();

    if (!data.results) {
      return graph;
    }

    // TOFIX: this is a temporary solution to handle the data structure (account_type, full_name, external_id)
    data.results.forEach((item: any) => {
      if (item.node) {
        const lastTransaction = item.node
          .customer_last_transaction as KytTransaction;
        const customerParticipant =
          lastTransaction?.from?.external_id === item.node.id
            ? lastTransaction?.from
            : lastTransaction?.to;
        const customerData = {
          external_id: item.node.customer
            ? customerParticipant?.external_id
            : null,
          full_name: customerParticipant?.full_name,
          account_type: customerParticipant?.account_type,
        };
        graph.nodes.push({
          ...item.node,
          ...customerData,
          expanded: false,
        });
      } else if (item.edge) {
        graph.edges.push({
          ...item.edge,
          source: item.edge.start_id,
          target: item.edge.end_id,
        });
      }
    });

    return graph;
  };

  static getPostHocPatterns = async () => {
    const response = await useFetch(`/web/kyt/post_hoc_patterns`, {
      method: "GET",
    });
    const data = await response.json();
    return data.patterns as KytPostHocPattern[];
  };

  static createPostHocPattern = async (
    pattern: KytPostHocPatternRequestOptions
  ) => {
    const response = await useFetch(`/web/kyt/post_hoc_pattern`, {
      method: "POST",
      body: JSON.stringify(pattern),
    });
    if (!is2XX(response.status)) {
      throw new Error(`Failed to create pattern ${pattern.name}`);
    }
  };

  static editPostHocPattern = async (
    id: string,
    pattern: Partial<KytPostHocPatternRequestOptions>
  ) => {
    const response = await useFetch(`/web/kyt/post_hoc_pattern/${id}`, {
      method: "POST",
      body: JSON.stringify(pattern),
    });
    if (!is2XX(response.status)) {
      throw new Error(`Failed to edit pattern ${id}`);
    }
  };

  static deletePostHocPattern = async (id: string) => {
    const response = await useFetch(`/web/kyt/post_hoc_pattern/${id}`, {
      method: "DELETE",
    });
    if (!is2XX(response.status)) {
      throw new Error(`Failed to delete pattern ${id}`);
    }
  };

  static getTransactionsAggregates = async (
    request: CustomerTransactionsAggregateRequestOptions
  ) => {
    const response = await useFetch(`/web/kyt/transactions/aggregates`, {
      method: "POST",
      body: JSON.stringify(request),
    });
    const data = await response.json();
    return data as CustomerTransactionsAggregate;
  };
}
