import { ButtonConfirm } from "@atoms/button/confirm";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import InputSuggestions from "@atoms/input/input-suggestions";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { Info } from "@atoms/text";
import { RiskResourceType, RiskTreeNode } from "@features/risk-decisions/types";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { useRiskElement } from "@features/risk-decisions/user-risk-element";
import {
  getRiskRoute,
  getRiskRouteDetails,
  getSubLabels,
} from "@features/risk-decisions/utils";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { atomFamily, useRecoilState } from "recoil";

export const RiskElementEditModalAtom = atomFamily<
  {
    open?: boolean;
    id?: string;
    duplicate?: boolean;
  },
  RiskResourceType
>({
  key: "RiskElementEditModalAtom",
  default: {
    open: false,
    id: "",
    duplicate: false,
  },
});

export const RiskElementEditModal = ({
  resource,
}: {
  resource: RiskResourceType;
}) => {
  const [{ open, id }, setOpen] = useRecoilState(
    RiskElementEditModalAtom(resource)
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        return setOpen({});
      }}
    >
      {open && (
        <RiskFactorEditModalContent resource={resource} key={id || ""} />
      )}
    </Modal>
  );
};

export const RiskFactorEditModalContent = ({
  resource,
}: {
  resource: RiskResourceType;
}) => {
  const [{ id, duplicate }, setOpen] = useRecoilState(
    RiskElementEditModalAtom(resource)
  );
  const { riskFactors } = useRiskFactors(resource);
  const { element, update, remove } = useRiskElement(resource, id || "");
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState(
    getSubLabels((element?.label || "") + (duplicate ? " - Copy" : ""))[0]
  );
  const [name, setName] = useState(
    getSubLabels((element?.label || "") + (duplicate ? " - Copy" : ""))[1]
  );
  const [type, setType] = useState(element?.type || "customer");
  const [code, setCode] = useState(
    (element?.code || "") + (duplicate ? "_copy" : "")
  );
  const [useWeight, setUseWeight] = useState(element?.use_weight || false);
  const [weight, setWeight] = useState(
    element?.weight != null ? element.weight : 1
  );
  const navigate = useNavigate();
  const location = useLocation();

  const confirmSave = async () => {
    setLoading(true);

    let defaultNode: RiskTreeNode = {
      id: "node_" + id,
      name: "Undefined",
      leaf: {
        outputs: {
          score: "U",
          action: "not_required",
          trigger: "false",
        },
      },
      output_node_ids: {},
    };

    if (resource === "kyt") {
      defaultNode = {
        id: "node_" + id,
        name: "Pass",
        leaf: {
          outputs: {
            score: "100",
          },
        },
        output_node_ids: {},
      };
    }

    const newVersion = await update({
      id: 0,
      // Defined a basic tree
      tree_data: element?.tree_data ?? {
        start_node_id: "node_" + id,
        nodes: [defaultNode],
      },
      ...(element || {}),
      ...(duplicate ? { id: undefined } : {}),
      type: type,
      group: group,
      label: name,
      code,
      use_weight: useWeight,
      weight,
    });
    if (location.pathname !== getRiskRoute(resource)) {
      navigate(
        getRiskRouteDetails(resource).replace(
          /:id/,
          newVersion?.id.toString() || ""
        )
      );
    }
    setOpen({});
    setLoading(false);
  };

  const confirmRemove = async () => {
    setLoading(true);
    await remove();
    navigate(getRiskRoute(resource));
    setOpen({});
    setLoading(false);
  };

  const availableGroups = _.sortBy(
    _.uniq(
      riskFactors
        .map((a) => a.group || getSubLabels(a.label)[0])
        .filter((a) => a)
    ),
    (a) => a
  );

  const resourceName = resource === "kyt" ? "rule" : "risk factor";

  const saveDisabled = useMemo(() => {
    const codeAlreadyExist = riskFactors.find((a) => a.code === code);
    const previous = riskFactors.find((a) => a.id === id);

    if (previous) {
      return (
        (codeAlreadyExist && previous.code !== codeAlreadyExist.code) ||
        (weight === previous.weight &&
          useWeight === previous.use_weight &&
          code === previous.code &&
          name === previous.label)
      );
    }
    return !name || !!("kyt" === resource && (!code || codeAlreadyExist));
  }, [id, group, resource, name, riskFactors, code, weight, useWeight]);

  return (
    <ModalContent
      title={
        (id ? (duplicate ? "Duplicate " : "Create ") : "Edit ") + resourceName
      }
    >
      <InputLabel
        label={resource === "kyt" ? "Rule name" : "Risk factor name"}
        input={
          <div className="flex flex-row">
            <InputSuggestions
              debounce={1}
              single
              placeholder="Group"
              className="focus:z-10 relative rounded-r-none"
              getSuggestions={async (query) => {
                return [
                  ...availableGroups.map((a) => ({ label: a, value: a })),
                  ...(query && !availableGroups.includes(query)
                    ? [{ label: query, value: query }]
                    : []),
                ];
              }}
              value={[group]}
              onChange={(value: string[]) => {
                setGroup(value[0]);
              }}
            />
            <Input
              className="focus:z-10 relative rounded-l-none -ml-px"
              placeholder="Rule name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        }
      />

      {resource === "kyt" && (
        <>
          <InputLabel
            className="mt-4"
            label={"Code"}
            input={
              <Input
                placeholder="Code"
                value={code}
                onChange={(e) =>
                  setCode(
                    e.target.value
                      ?.toLocaleLowerCase()
                      .replace(/[^a-z0-9]/g, "_") || ""
                  )
                }
              />
            }
          />
          {riskFactors.find((a) => a.code === code && a.id !== id) && (
            <Info className="text-red-500" noColor>
              Code already used for another rule
            </Info>
          )}
        </>
      )}

      <InputLabel
        className="mt-4"
        label={"Type"}
        input={
          <Select
            disabled={element?.id !== undefined}
            value={type}
            onChange={(e) => setType(e.target.value as any)}
          >
            {resource === "kyt" && (
              <>
                <option value="transaction">Transaction</option>
              </>
            )}
            {resource !== "kyt" && (
              <>
                <option value="customer">Customer</option>
                <option value="customer_relation">Customer's relations</option>
              </>
            )}
          </Select>
        }
      />

      <div className="my-6">
        <Checkbox
          label="Use weight"
          value={useWeight && weight > 0}
          onChange={(s) => {
            setUseWeight(s);
            setWeight(weight || 1);
          }}
        />
        {useWeight && weight > 0 && (
          <Input
            className="mt-2"
            placeholder="Weight"
            value={weight}
            onChange={(e) => {
              setWeight(parseInt(e.target.value || "0") || 0);
            }}
          />
        )}
        <Info className="block mt-2">
          By default the {resource === "kyt" ? "minimum" : "maximum"}{" "}
          {resourceName} result will be used. Instead you can configure several{" "}
          {resourceName}s to compute a weighted average score.
        </Info>
      </div>

      <div className="my-6">
        <Checkbox
          label="Exclude from scoring"
          value={useWeight && weight === 0}
          onChange={(s) => {
            setUseWeight(s);
            setWeight(s ? 0 : 1);
          }}
        />
        <Info className="block mt-2">
          Excluded {resourceName}s will be displayed but won't have an effect on
          the final result.
        </Info>
      </div>

      <ButtonConfirm
        confirmTitle={"Save " + resourceName}
        confirmMessage="This will be applied now"
        className="mt-6 float-right"
        theme="primary"
        loading={loading}
        disabled={saveDisabled}
        onClick={confirmSave}
      >
        {!id || duplicate ? "Create" : "Save"}
      </ButtonConfirm>

      {!!(id && !duplicate) && (
        <ButtonConfirm
          confirmTitle={"Delete " + resourceName}
          confirmMessage="This will be applied now and have an effect on all ongoing risk scoring tasks or scanning tasks."
          className="mt-6"
          theme="danger"
          loading={loading}
          onClick={confirmRemove}
        >
          Remove
        </ButtonConfirm>
      )}
    </ModalContent>
  );
};
