import { Button } from "@atoms/button/button";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { Info, SectionSmall } from "@atoms/text";
import _ from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import { atom, useRecoilState } from "recoil";

export type ViewType = {
  id: string;
  name: string;
  columns: string[];
};

export const CustomViewAtom = atom<Partial<ViewType> | null>({
  key: "CustomViewAtom",
  default: null,
});

export const useCustomViews = () => {
  const views = useQuery({
    queryKey: ["custom_views"],
    queryFn: () =>
      JSON.parse(
        localStorage.getItem("saved.custom_views") || "[]"
      ) as ViewType[],
  });

  const upsert = (view: ViewType) => {
    const current = views.data || [];
    const updated = current
      .filter((a) => a.id !== view.id)
      .concat(view)
      .sort((a, b) => a.name.localeCompare(b.name));
    localStorage.setItem("saved.custom_views", JSON.stringify(updated));
    views.refetch();
  };

  const remove = (id: string) => {
    const current = views.data || [];
    localStorage.setItem(
      "saved.custom_views",
      JSON.stringify(current.filter((a) => a.id !== id))
    );
    views.refetch();
  };

  return { views, upsert, remove };
};

export const CustomView = ({
  columns,
}: {
  columns: { title: string; key: string }[];
}) => {
  const [view, setView] = useRecoilState(CustomViewAtom);
  const { views, remove, upsert } = useCustomViews();
  const currentView =
    views?.data?.find((a) => a.id === view?.id) ||
    ({
      id: "",
      name: view?.name,
      columns: view?.columns,
    } as ViewType);
  return (
    <Modal open={view !== null} onClose={() => setView(null)}>
      {view !== null && (
        <CustomViewContent
          columns={columns}
          initial={currentView}
          cancel={setView}
          save={(view) => {
            if (view) {
              view.columns = _.sortBy(view.columns, "key");
              upsert(view);
            }
            setView(null);
          }}
          remove={(id) => {
            remove(id);
            setView(null);
          }}
        />
      )}
    </Modal>
  );
};

export const CustomViewContent = (props: {
  initial: ViewType;
  cancel: (view: ViewType | null) => void;
  save: (view: ViewType | null) => void;
  remove: (id: string) => void;
  columns: { title: string; key: string }[];
}) => {
  const [view, setView] = useState(props.initial);

  const buildCheckboxes = (keep: string[]) => {
    return keep
      .map((a) => props.columns.find((b) => b.key === a)!)
      .filter(Boolean)
      .map((col) => (
        <Checkbox
          key={col.key}
          label={
            <>
              {col.title} <Info>({col.key})</Info>
            </>
          }
          value={view.columns.includes(col.key)}
          onChange={(e) =>
            setView({
              ...view,
              columns: view.columns
                .filter((a) => a !== col.key)
                .concat(e ? [col.key] : []),
            })
          }
        />
      ));
  };

  return (
    <ModalContent title="Custom view">
      <InputLabel
        label="Name"
        input={
          <Input
            value={view.name}
            onChange={(e) => setView({ ...view, name: e.target.value })}
          />
        }
      />
      <br />
      <SectionSmall>General</SectionSmall>
      <div className="space-y-1">
        {buildCheckboxes([
          "review_groups",
          "first_names",
          "last_names",
          "trading_names",
          "company_names",
          "date_of_birth",
          "domicile_code",
          "nationality_code",
          "contacts",
          "customer_last_updated_at",
        ])}
      </div>
      <br />
      <SectionSmall>Go!Risk</SectionSmall>
      <div className="space-y-1">
        {buildCheckboxes([
          "overall_risk",
          "edd_state",
          "risk_factors",
          "edd_comment",
          "edd_last_updated_at",
        ])}
      </div>
      <br />
      <SectionSmall>Go!Scan</SectionSmall>
      <div className="space-y-1">{buildCheckboxes(["scan"])}</div>
      <br />
      <SectionSmall>Go!Chat</SectionSmall>
      <div className="space-y-1">
        {buildCheckboxes(["open_chat_categories", "closed_chat_categories"])}
      </div>
      <br />
      <SectionSmall>Go!Vid</SectionSmall>
      <div className="space-y-1">
        {buildCheckboxes(
          props.columns
            .filter((a) => a.key.indexOf("vid_") === 0)
            .map((a) => a.key)
        )}
      </div>
      <br />
      <SectionSmall>Go!KYT</SectionSmall>
      <div className="space-y-1">
        {buildCheckboxes(
          view.columns.filter((a) => a.indexOf("kyt_data") === 0)
        )}
        <br />
        <Select
          onChange={(e) => {
            e.preventDefault();
            setView({
              ...view,
              columns: _.uniq(view.columns.concat(e.target.value)),
            });
            e.target.selectedIndex = 0;
          }}
        >
          <option disabled selected>
            Add column
          </option>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(
            (duration) =>
              ["all", "in", "out"].map((dir) =>
                ["sum", "min", "max", "count"].map((agg) => (
                  <option
                    key={`${agg}_${dir}_${duration}`}
                    value={`kyt_data.all.${dir}.${agg}_${duration}m`}
                  >{`+ ${dir} ${agg} last ${duration}m`}</option>
                ))
              )
          )}
        </Select>
      </div>
      <br />
      <SectionSmall>Other</SectionSmall>
      <div className="space-y-1">
        {buildCheckboxes(
          props.columns
            .filter((a) => a.key.indexOf("custom_fields_labels") === 0)
            .map((a) => a.key)
            .sort()
        )}
      </div>
      <br />
      <Button
        onClick={() =>
          props.save({ ...view, id: view.id || Date.now().toString() })
        }
        theme="primary"
        className="float-right"
      >
        Save
      </Button>
      <Button onClick={() => props.cancel(null)} theme="outlined">
        Cancel
      </Button>
      {!!view.id && (
        <Button
          onClick={() => props.remove(view.id)}
          theme="danger"
          className="ml-2"
        >
          Delete
        </Button>
      )}
    </ModalContent>
  );
};
