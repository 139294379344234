import { useQuery, UseQueryResult } from "react-query";
import { TotpRegisterResponse } from "../types";
import { TotpApiClient } from "../api-client/api-client";

export function useTotp() {
  const useTotpGenerateQrCode = (
    email: string
  ): UseQueryResult<TotpRegisterResponse> =>
    useQuery({
      queryFn: () => TotpApiClient.getTotpGenerateQrCode(email),
      refetchOnWindowFocus: false,
    });

  const setTotpVerify = async (props: {
    userCode: string;
    secret?: string;
  }) => {
    try {
      const response = await TotpApiClient.newTotpVerify(props);
      return response;
    } catch (error) {
      console.log("error editing matrix", error);
    }
  };

  return { useTotpGenerateQrCode, setTotpVerify };
}
