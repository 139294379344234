import { BaseSmall, InfoSmall } from "@atoms/text";
import { SessionAlertType } from "@features/sessions/types";
import { AlertTag } from "./alert-tag";
import { QuestionContent } from "./question-content";
import { SessionBlock } from "./session-block";

type SessionInformationsReviewProps = {
  alert: SessionAlertType;
};

export function SessionInformationsReview({
  alert,
}: SessionInformationsReviewProps) {
  return (
    <>
      {alert?.informations?.map((information, index) => {
        return (
          <SessionBlock
            key={index}
            id="information-question"
            title={information.question_name}
            subtitle={information.question_content}
            tries={information.tries.length}
            suffix={<AlertTag state={alert.state} />}
            render={(tryIndex) => (
              <div className="-mt-2">
                {!!information.possible_answers?.length && (
                  <div>
                    <InfoSmall>Allowed answers : </InfoSmall>
                    <BaseSmall>
                      {(information.possible_answers || []).join(", ")}
                    </BaseSmall>
                  </div>
                )}
                {!!information.should_not_contain?.length && (
                  <div>
                    <InfoSmall>Should not contain : </InfoSmall>
                    <BaseSmall>
                      {(information.should_not_contain || []).join(", ")}
                    </BaseSmall>
                  </div>
                )}
                <QuestionContent
                  answer={information.tries[tryIndex as number]}
                />
              </div>
            )}
          />
        );
      })}
    </>
  );
}
