import { PageBlock, PageBlockHr } from "@atoms/layout/page-block";
import { Base, Info, SectionSmall } from "@atoms/text";
import { SessionAlertType } from "@features/sessions/types";
import { AlertTag } from "./alert-tag";
import { LABEL_TYPE_COLORS } from "@features/sessions/utils";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";

export default function SessionCaptchaReview(props: {
  alert: SessionAlertType;
}) {
  return (
    <PageBlock>
      <div id="captcha" className="flex flex-row justify-between">
        <SectionSmall>Captcha</SectionSmall>
        <AlertTag state={props.alert.state} />
      </div>
      <Info>Robot Scoring</Info>
      {props.alert.score !== undefined && (
        <>
          <PageBlockHr />
          <div
            className={`rounded flex flex-row space-x-1 px-2 py-3 text-white items-center ${
              props.alert.score === 100
                ? LABEL_TYPE_COLORS.POSITIVE
                : props.alert.score >= 50
                ? LABEL_TYPE_COLORS.NEUTRAL
                : LABEL_TYPE_COLORS.NEGATIVE
            }
            `}
          >
            {props.alert.score === 100 ? (
              <CheckCircleIcon className="h-6 w-6" />
            ) : props.alert.score >= 50 ? (
              <ExclamationTriangleIcon className="h-6 w-6" />
            ) : (
              <XCircleIcon className="h-6 w-6" />
            )}
            <Base noColor className="grow">
              {props.alert.score === 100
                ? "PASSED"
                : props.alert.score >= 50
                ? "CONSIDER"
                : "FAILED"}
            </Base>
          </div>
        </>
      )}
    </PageBlock>
  );
}
