import { OptionsType } from "@atoms/dropdown";
import { UpdateMultipleAlertsModalAtom } from "@components/bulk-alert-status";
import { CogIcon } from "@heroicons/react/24/outline";
import { SharedProductsMenu } from "@views/client/customers/customer-details/menu";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useAlert } from "../../../../features/alerts/state/use-alerts";
import { useHasAccess } from "../../../../features/auth/state/use-access";

export const Menu = () => {
  const { id } = useParams<{ id: string }>();
  const hasAccess = useHasAccess();
  const { alert } = useAlert(id || "");

  const setOpen = useSetRecoilState(UpdateMultipleAlertsModalAtom);

  return (
    <SharedProductsMenu
      id={alert?.customer_id!}
      additionalOptions={[
        ...(hasAccess("POST_FILTERING_UPDATE")
          ? ([
              {
                onClick: () =>
                  setOpen({
                    open: true,
                    customer_id: alert?.customer_external_customer_id || "",
                  }),
                icon: (p: any) => <CogIcon {...p} />,
                label: "Bulk update",
              },
              {
                type: "divider",
                label: "",
              },
            ] as OptionsType[])
          : []),
      ]}
    />
  );
};
