import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { PressApiClient } from "../api-client/api-client";
import { ReportSearchResultType } from "../types";
import { ReportsQueryInputAtom } from "./store";

export const usePressReports = () => {
  const [query, setQuery] = useRecoilState(ReportsQueryInputAtom);

  const schema = useQuery({
    queryKey: ["reports", "schema"],
    queryFn: PressApiClient.getReportsSearchSchema,
  });

  const reports = useQuery<{ data: ReportSearchResultType[]; total: number }>({
    queryKey: ["reports", query.query, query.options],
    queryFn: async () => {
      return await PressApiClient.searchReportsAdvanced(
        query.query,
        query.options
      );
    },
    cacheTime: 2000,
  });

  const fetchReport = async (
    options: Partial<{
      limit: number;
      offset: number;
      order_by: string;
      order_way: "ASC" | "DESC";
    }>
  ) => {
    return await PressApiClient.searchReportsAdvanced(query.query, options);
  };

  return {
    reports: reports.data || { data: [], total: 0 },
    loading: reports.isLoading || schema.isLoading,
    search: setQuery,
    fetchReport,
    schema: (schema.data || []).map((field) => {
      if (field.external_key === "assignees") {
        field.value_type = "string"; // Number ids can't work with this field
      }
      return field;
    }),
  };
};
