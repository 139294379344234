import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Checkbox } from "@atoms/input/input-checkbox";
import {
  InputLabel,
  InputLabelProps,
} from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { Page } from "@atoms/layout/page";
import { PageBlockHr } from "@atoms/layout/page-block";
import Link from "@atoms/link";
import Select from "@atoms/select";
import { Info, InfoSmall, Section, SectionSmall, Title } from "@atoms/text";
import { PEPRoles, RCATypes, SITypes } from "@features/alerts-filters/enum";
import { useAlertsPostFilters } from "@features/alerts-filters/state/use-alerts-filters";
import {
  PostFilteringSubSettingsType,
  PostFilteringType,
} from "@features/alerts-filters/types";
import { useHasAccess } from "@features/auth/state/use-access";
import { ROUTES } from "@features/routes";
import { useControlledEffect } from "@features/utils";
import { TrashIcon, Bars4Icon } from "@heroicons/react/24/outline";

import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";

const categories = [
  "sip_settings",
  "rca_sip_settings",
  "sie_settings",
  "rca_sie_settings",
  "pep_settings",
  "rca_pep_settings",
  "san_settings",
  "rca_san_settings",
  "ame_settings",
  "soe_settings",
  "rca_soe_settings",
  "sop_settings",
  "rca_sop_settings",
];

const categoryLabels = {
  sip_settings: "SIP",
  rca_sip_settings: "RCA SIP",
  sie_settings: "SIE",
  rca_sie_settings: "RCA SIE",
  pep_settings: "PEP",
  rca_pep_settings: "RCA PEP",
  san_settings: "SAN",
  rca_san_settings: "RCA SAN",
  ame_settings: "AME",
  soe_settings: "SOE",
  rca_soe_settings: "RCA SOE",
  sop_settings: "SOP",
  rca_sop_settings: "RCA SOP",
};

const sanctionListsPreset: { [key: string]: string } = {
  "^(ABE|AMF|DGCCRF|Bank of France|French) .*$":
    "France (Includes AMF, ABE, DGCCRF and Bank of France)",
  "^(EC |Europe|Vessels - EC).*$": "Europe (EC and EC Vessels)",
  "^CSSF .*$": "Luxembourg (CSSF)",
  "^DFAT .*$": "Australia",
  "^DFATD .*$": "Canada",
  "^EC (CFSP) .*$": "Europe EC (CFSP)",
  "^EC [0-9].*$": "Europe EC (non CFSP)",
  "^UN Security Council Resolution .*$": "UN Security Council Resolution",
  "^US Executive Order .*$": "US Executive Order",
  "^Vessels - EC (CFSP) .*$": "Vessels - EC (CFSP)",
  "^Vessels - EC [0-9].*$": "Vessels - EC (non CFSP)",
  "^OFAC .*$": "OFAC",
  "^SECO .*$": "SECO (Switzerland)",
  "^MLCA .*$": "MLCA (Switzerland)",
  "^Japanese Finance Ministry .*$": "Japanese Finance Ministry",
  "^US Department of State .*$": "US Department of State",
  "^UN Security Council Vessels .*$": "UN Security Council Vessels",
  "^South Korea .*$": "South Korea",
  "^MAS .*$": "MAS (Singapore)",
  "^French Economy Ministry .*$": "French Economy Ministry",
  "^Federal Financial Monitoring Services .*$":
    "Federal Financial Monitoring Services (Russia)",
  "^Government of Russia .*$": "Government of Russia",
};

const InheritAtom = atom<any>({
  key: "InheritAtom",
  default: {},
});

export const PostFilteringEditPage = () => {
  const { id } = useParams();
  const { filters, loading, save, remove } = useAlertsPostFilters();
  const [inherit, setInherit] = useRecoilState(InheritAtom);
  const navigate = useNavigate();
  const hasAccess = useHasAccess();
  const readOnly = !hasAccess("POST_FILTERING_UPDATE");

  const [settings, setSettings] = useState(
    filters.find((f) => f.name === (id === "default" ? "" : id))
  );

  useEffect(() => {
    setSettings(filters.find((f) => f.name === (id === "default" ? "" : id)));
  }, [filters, id]);

  useControlledEffect(() => {
    let inherit = {};
    (categories as (keyof PostFilteringType)[])
      .filter((f) => settings?.settings[f]?.active)
      .forEach((f) => {
        Object.keys(settings?.settings.default_settings || {}).forEach((k) => {
          if (k !== "disable") {
            inherit = {
              ...inherit,
              [`${f}_${k}`]: _.isEqual(
                (settings?.settings as any)?.[f]?.[k] || "",
                (settings?.settings.default_settings as any)?.[k] || ""
              ),
            };
          }
        });
      });
    setInherit(inherit);
  }, [
    (categories as (keyof PostFilteringType)[]).filter(
      (f) => settings?.settings[f]?.active
    ).length,
  ]);

  const applyInheritance = (settings: PostFilteringType) => {
    const newSettings = _.cloneDeep(settings);
    (categories as (keyof PostFilteringType)[])
      .filter((f) => settings?.[f]?.active && f !== "default_settings")
      .forEach((f) => {
        Object.keys(settings?.default_settings || {}).forEach((k) => {
          if (inherit[`${f}_${k}`]) {
            (newSettings as any)[f][k] = (settings?.default_settings as any)[k];
          }
        });
      });
    return newSettings;
  };

  if (!settings?.settings) {
    return <></>;
  }

  return (
    <Page>
      <div className="float-right">
        <div className="m-auto inline-flex items-center">
          <Link className="flex" to={ROUTES.PostFiltering}>
            <Button size="sm" theme="outlined">
              <Bars4Icon className="h-5 w-5 mr-2" /> Back to all filters
            </Button>
          </Link>
        </div>
      </div>
      <Title>Filter '{id}'</Title>
      <div className="mt-4" />

      <div className="mt-4 mt-2 w-full bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 py-4 rounded px-4">
        <SectionSmall>Filter settings</SectionSmall>
        <SubFilterForm
          category="default_settings"
          disabled={readOnly}
          showPEPSettings
          showRCASettings
          showSISettings
          isDefaultSettings
          value={settings?.settings["default_settings"]!}
          onChange={(s) =>
            setSettings({
              ...settings!,
              settings: {
                ...(settings?.settings as PostFilteringType),
                default_settings: s,
              },
            })
          }
        />
      </div>

      {!readOnly && (
        <Select
          className="w-auto float-right mt-4"
          value=""
          disabled={readOnly}
          onChange={(e) => {
            const category = e.target.value as keyof PostFilteringType;
            setSettings({
              ...settings!,
              settings: {
                ...(settings?.settings as PostFilteringType),
                [category]: {
                  ..._.cloneDeep(settings?.settings?.default_settings!),
                  active: true,
                },
              },
            });
          }}
        >
          <option value="" disabled>
            Add a category specific filter
          </option>
          {categories.map((c) => (
            <option key={c} value={c}>
              {categoryLabels[c as keyof typeof categoryLabels]}
            </option>
          ))}
        </Select>
      )}
      <Section className="mt-4">Category specific filters</Section>

      {(categories as (keyof PostFilteringType)[]).filter(
        (f) => settings?.settings[f]?.active
      ).length === 0 && <Info>No category specific filters is used.</Info>}

      {(categories as (keyof PostFilteringType)[])
        .filter((f) => settings?.settings[f]?.active)
        .map((f) => (
          <div
            key={f}
            className="mt-4 mt-2 w-full bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 py-4 rounded px-4"
          >
            {!readOnly && (
              <Button
                disabled={readOnly}
                className="float-right"
                size="sm"
                theme="outlined"
                onClick={() => {
                  setSettings({
                    ...settings!,
                    settings: {
                      ...(settings?.settings as PostFilteringType),
                      [f]: {
                        ...settings?.settings[f],
                        active: false,
                      },
                    },
                  });
                }}
              >
                Remove category filter
              </Button>
            )}

            <SectionSmall>
              {categoryLabels[f as keyof typeof categoryLabels]}
              <Info className="ml-2">
                If the record is{" "}
                {categoryLabels[f as keyof typeof categoryLabels]} then apply
                this filter instead.
              </Info>
            </SectionSmall>

            <SubFilterForm
              category={f}
              disabled={readOnly}
              showIgnoreSettings
              showPEPSettings={f.includes("pep")}
              showRCASettings={f.includes("rca")}
              showSISettings={f.includes("sip") || f.includes("sie")}
              value={settings?.settings[f]!}
              onChange={(s) =>
                setSettings({
                  ...settings!,
                  settings: {
                    ...(settings?.settings as PostFilteringType),
                    [f]: s,
                  },
                })
              }
            />
          </div>
        ))}

      {!readOnly && (
        <>
          {" "}
          <hr className="opacity-50 mt-6" />
          <div className="mt-8">
            <ButtonConfirm
              confirmTitle="Note about updating filters"
              confirmMessage={
                settings?.name !== ""
                  ? "Once updated some transactional (via-API) name screening will start using this new parameters."
                  : "Once updated the ongoing daily screening will start using this new parameters."
              }
              loading={loading}
              theme="primary"
              className="mr-2"
              onClick={async () => {
                await save(
                  settings?.name!,
                  applyInheritance(settings?.settings!)
                );
              }}
            >
              Update filter
            </ButtonConfirm>
            {settings?.name !== "" && (
              <ButtonConfirm
                loading={loading}
                theme="danger"
                onClick={async () => {
                  if (await remove(settings?.name!)) {
                    navigate(ROUTES.PostFiltering);
                  }
                }}
              >
                Delete
              </ButtonConfirm>
            )}
          </div>
        </>
      )}
    </Page>
  );
};

const SubFilterForm = ({
  category,
  value,
  disabled,
  onChange,
  showPEPSettings,
  showRCASettings,
  showSISettings,
  showIgnoreSettings,
  isDefaultSettings,
}: {
  category: string;
  value: PostFilteringSubSettingsType;
  disabled?: boolean;
  onChange: (s: PostFilteringSubSettingsType) => void;
  showPEPSettings?: boolean;
  showRCASettings?: boolean;
  showSISettings?: boolean;
  showIgnoreSettings?: boolean;
  isDefaultSettings?: boolean;
}) => {
  const [sanFilter, setSanFilter] = useState<string>("");

  if (!value) return <></>;

  return (
    <>
      {showIgnoreSettings && (
        <>
          <InputLabel
            className="mt-2"
            label="Ignore this category"
            input={
              <Checkbox
                disabled={disabled}
                value={value.disable}
                onChange={(e) => onChange({ ...value, disable: e })}
                label="Hits of this category will be ignored (except if they match with an other category)"
              />
            }
          />
          {!value.disable && <PageBlockHr />}
        </>
      )}
      {!value.disable && (
        <>
          <div className="flex flex-row mt-4">
            <span data-tooltip="Alerts with score below specified threshold will not be raised for review. Default recommended threshold is set at 95%">
              <InputLabelWithInherit
                disableInherit={isDefaultSettings || disabled}
                inherit={[category + "_threshold"]}
                label="Name match threshold (%)"
                input={
                  <Input
                    disabled={disabled}
                    placeholder="90"
                    type="number"
                    value={value.threshold || ""}
                    onChange={(e) =>
                      onChange({
                        ...value,
                        threshold: parseInt(e.target.value) || 0,
                      })
                    }
                  />
                }
              />
            </span>

            <InputLabelWithInherit
              disableInherit={isDefaultSettings || disabled}
              inherit={[category + "_first_name_weight"]}
              className="ml-8"
              label="First name weight"
              input={
                <Input
                  disabled={disabled}
                  placeholder="33"
                  type="number"
                  value={value.first_name_weight}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      first_name_weight: parseInt(e.target.value),
                    })
                  }
                />
              }
            />
            <InputLabelWithInherit
              disableInherit={isDefaultSettings || disabled}
              inherit={[category + "_middle_name_weight"]}
              className="ml-2"
              label="Middle name weight"
              input={
                <Input
                  disabled={disabled}
                  placeholder="33"
                  type="number"
                  value={value.middle_name_weight}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      middle_name_weight: parseInt(e.target.value),
                    })
                  }
                />
              }
            />
            <InputLabelWithInherit
              disableInherit={isDefaultSettings || disabled}
              inherit={[category + "_last_name_weight"]}
              className="ml-2"
              label="Last name weight"
              input={
                <Input
                  disabled={disabled}
                  placeholder="34"
                  type="number"
                  value={value.last_name_weight}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      last_name_weight: parseInt(e.target.value),
                    })
                  }
                />
              }
            />
            <div className="grow" />
          </div>{" "}
          <PageBlockHr />
          <div className="mt-4" />
          <div>
            <InputLabelWithInherit
              disableInherit={isDefaultSettings || disabled}
              inherit={[category + "_date_of_birth"]}
              className="inline-block"
              label="Date of birth matching"
              input={
                <>
                  <Select
                    disabled={disabled}
                    className="w-auto"
                    value={
                      value.date_of_birth
                        ? value.date_of_birth_method
                        : "ignore"
                    }
                    onChange={(e) =>
                      onChange({
                        ...value,
                        date_of_birth_method: e.target.value as any,
                        date_of_birth: e.target.value !== "ignore",
                      })
                    }
                  >
                    <option value="ignore">Ignore</option>
                    <option value="exact">Exact</option>
                    <option value="monthyear">Month and year only</option>
                    <option value="year">Year only</option>
                    <option value="delta">Custom delta</option>
                  </Select>
                  {value.date_of_birth &&
                    value.date_of_birth_method === "exact" && (
                      <InfoSmall className="ml-2">
                        Only raise matching hits with exactly the same date of
                        birth or none defined. Also keep inverted month-day
                        matching hits.
                      </InfoSmall>
                    )}
                  {value.date_of_birth &&
                    value.date_of_birth_method === "year" && (
                      <InfoSmall className="ml-2">
                        Only raise matching hits with matching years of birth or
                        none defined.
                      </InfoSmall>
                    )}
                  {value.date_of_birth &&
                    value.date_of_birth_method === "monthyear" && (
                      <InfoSmall className="ml-2">
                        Only raise matching hits with matching years and month
                        of birth or none defined.
                      </InfoSmall>
                    )}
                </>
              }
            />

            {value.date_of_birth && value.date_of_birth_method === "delta" && (
              <InputLabelWithInherit
                disableInherit={isDefaultSettings || disabled}
                inherit={[category + "_date_of_birth_delta"]}
                className="inline-block ml-2"
                label="Delta in days"
                input={
                  <Input
                    disabled={disabled}
                    className="w-auto"
                    type="number"
                    value={value.date_of_birth_delta || "0"}
                    onChange={(e) => {
                      onChange({
                        ...value,
                        date_of_birth_delta: parseInt(e.target.value),
                      });
                    }}
                  />
                }
              />
            )}
          </div>
          <div className="mt-4">
            <span data-tooltip="When activated, hits where country of nationality, domicile or jurisdiction does not match with the customer's countries will be automatically dismissed">
              <InputLabelWithInherit
                disableInherit={isDefaultSettings || disabled}
                inherit={[
                  category + "_country_of_nationality",
                  category + "_country_of_residence",
                ]}
                className="mt-2"
                label="Country must match"
                input={
                  <Checkbox
                    disabled={disabled}
                    value={
                      value.country_of_nationality && value.country_of_residence
                    }
                    onChange={(e) =>
                      onChange({
                        ...value,
                        country_of_nationality: e,
                        country_of_residence: e,
                      })
                    }
                    label="Hit and customer countries must match"
                  />
                }
              />
            </span>
          </div>
          <PageBlockHr />
          <div className="mt-4 flex space-x-2">
            <span
              className="w-1/4"
              data-tooltip="When activated, hits where country of nationality, domicile or jurisdiction does not match with the customer's countries will be automatically dismissed"
            >
              <InputLabelWithInherit
                disableInherit={isDefaultSettings || disabled}
                inherit={[category + "_must_be_active"]}
                label="Status"
                input={
                  <Checkbox
                    disabled={disabled}
                    value={value.must_be_active}
                    onChange={(e) => onChange({ ...value, must_be_active: e })}
                    label="Hit must be active"
                  />
                }
              />
            </span>

            <span
              className="w-1/4"
              data-tooltip="When activated, deceased hits will be automatically dismissed"
            >
              <InputLabelWithInherit
                disableInherit={isDefaultSettings || disabled}
                inherit={[category + "_must_not_be_deceased"]}
                label="Deceased"
                input={
                  <Checkbox
                    disabled={disabled}
                    value={value.must_not_be_deceased}
                    onChange={(e) =>
                      onChange({ ...value, must_not_be_deceased: e })
                    }
                    label="Hit must not be deceased"
                  />
                }
              />
            </span>

            <span
              className="w-1/4"
              data-tooltip="When activated, hits with no known date of birth will be automatically dismissed"
            >
              <InputLabelWithInherit
                disableInherit={isDefaultSettings || disabled}
                inherit={[category + "_must_have_a_birth_date"]}
                label="Must have a known date of birth"
                input={
                  <Checkbox
                    disabled={disabled}
                    value={value.must_have_a_birth_date}
                    onChange={(e) =>
                      onChange({ ...value, must_have_a_birth_date: e })
                    }
                    label="Candidate must have a date of birth"
                  />
                }
              />
            </span>
          </div>
          <br />
          <div className="mt-4 flex space-x-2">
            <span
              className="w-1/4"
              data-tooltip="Number of months a record is still considered as active"
            >
              <InputLabelWithInherit
                disableInherit={isDefaultSettings || disabled}
                inherit={[category + "_must_be_active_delay"]}
                label="Inactive delay"
                input={
                  <Select
                    disabled={disabled}
                    className="w-auto"
                    value={value.must_be_active_delay}
                    onChange={(e) => {
                      onChange({
                        ...value,
                        must_be_active_delay: parseInt(e.target.value),
                      });
                    }}
                  >
                    <option value="0">0 month</option>
                    <option value="1">1 month</option>
                    <option value="6">6 month</option>
                    <option value="12">12 month</option>
                    <option value="18">18 month</option>
                    <option value="24">24 month</option>
                    <option value="36">36 month</option>
                  </Select>
                }
              />
            </span>

            {isDefaultSettings && (
              <span
                className="w-1/4"
                data-tooltip="This will search for alerts in both firms and individuals"
              >
                <InputLabelWithInherit
                  disableInherit={isDefaultSettings || disabled}
                  inherit={[category + "_cross_entities_mode"]}
                  label="Cross entity types matching"
                  input={
                    <Select
                      disabled={disabled}
                      className="w-auto"
                      value={value.cross_entities_mode}
                      onChange={(e) =>
                        onChange({
                          ...value,
                          cross_entities_mode: e.target.value,
                        })
                      }
                    >
                      <option value="disabled">Disabled</option>
                      <option value="both">Both</option>
                      <option value="firmtoindividual">Firms only</option>
                      <option value="individualtofirm">Individuals only</option>
                    </Select>
                  }
                />
              </span>
            )}
          </div>
          <PageBlockHr />
          {showPEPSettings && (
            <div className="mt-4">
              <InputLabelWithInherit
                disableInherit={isDefaultSettings || disabled}
                inherit={[category + "_restrict_role_types"]}
                label="PEP roles types (empty for all)"
                input={
                  <SelectMultiple
                    disabled={disabled}
                    className="w-auto"
                    value={
                      value.restrict_role_types
                        ?.split(",")
                        .filter((a) => a.trim()) || []
                    }
                    onChange={(e) =>
                      onChange({
                        ...value,
                        restrict_role_types: e.join(","),
                      })
                    }
                    options={PEPRoles.map((a) => ({
                      label: a.name,
                      value: `${a.code}`,
                    }))}
                  />
                }
              />
            </div>
          )}
          {showSISettings && (
            <div className="mt-4">
              <InputLabelWithInherit
                disableInherit={isDefaultSettings || disabled}
                inherit={[category + "_restrict_si_types"]}
                label="SI categories (empty for all)"
                input={
                  <SelectMultiple
                    disabled={disabled}
                    className="w-auto"
                    value={SITypes.filter((a) =>
                      a.codes.some((c) =>
                        (value.restrict_si_types || "")
                          .split(",")
                          .includes(`${c}`)
                      )
                    ).map((a) => a.name)}
                    onChange={(e) =>
                      onChange({
                        ...value,
                        restrict_si_types: e
                          .map(
                            (a) =>
                              SITypes.find((s) => s.name === a)?.codes || []
                          )
                          .reduce((a, b) => [...a, ...b], [])
                          .join(","),
                      })
                    }
                    options={SITypes.map((a) => ({
                      label: a.name,
                      value: a.name,
                    }))}
                  />
                }
              />
            </div>
          )}
          {showSISettings && (
            <div className="mt-4 ">
              <InputLabelWithInherit
                disableInherit={isDefaultSettings || disabled}
                inherit={[category + "_restrict_san_types"]}
                label="Sanction lists (empty for all)"
                input={
                  <>
                    {!disabled && (
                      <div className="flex flex-row">
                        <Input
                          disabled={disabled}
                          value={sanFilter}
                          onChange={(e) => setSanFilter(e.target.value)}
                          placeholder={"^.*$"}
                          className="grow rounded-r-none"
                        />
                        <Select
                          disabled={disabled}
                          className="w-auto -ml-px  rounded-l-none"
                          value={""}
                          onChange={(e) => {
                            setSanFilter(e.target.value);
                          }}
                        >
                          <option disabled value="">
                            Use predefined RegExp
                          </option>
                          {Object.keys(sanctionListsPreset).map((a: string) => (
                            <option key={a} value={a}>
                              {sanctionListsPreset[a]}
                            </option>
                          ))}
                        </Select>
                        <Button
                          disabled={disabled}
                          theme="default"
                          size="md"
                          className="ml-1"
                          onClick={() => {
                            onChange({
                              ...value,
                              restrict_san_types: [
                                ...(value.restrict_san_types || []),
                                sanFilter,
                              ],
                            });
                            setSanFilter("");
                          }}
                        >
                          + Add
                        </Button>
                      </div>
                    )}

                    <div className="-mt-4 w-full border-l-2 border-slate-500 pt-4 pl-4">
                      {!value.restrict_san_types?.length && (
                        <>
                          <Info>Keeping all sanction lists.</Info>
                        </>
                      )}
                      {!!value.restrict_san_types?.length && (
                        <>
                          <Info className="block">
                            Keep only lists matching:
                          </Info>
                        </>
                      )}
                      {value.restrict_san_types?.map((a, i) => (
                        <Tag
                          key={i}
                          className="w-auto inline-flex flex-row items-center m-1"
                        >
                          <div className="mr-2">
                            {a}{" "}
                            {sanctionListsPreset[a] && (
                              <Info>({sanctionListsPreset[a]})</Info>
                            )}
                          </div>
                          <Button
                            disabled={disabled}
                            theme="danger"
                            className="!p-1 h-7 my-1 shrink-0"
                            size="sm"
                            onClick={() => {
                              onChange({
                                ...value,
                                restrict_san_types:
                                  value.restrict_san_types.filter(
                                    (b) => b !== a
                                  ),
                              });
                            }}
                          >
                            <TrashIcon className="h-4 w-4" />
                          </Button>
                        </Tag>
                      ))}
                      {!!value.restrict_san_types?.length && (
                        <Info className="block mt-1">
                          We use regular expressions, more information at{" "}
                          <Link target="_blank" href="https://regexone.com/">
                            https://regexone.com/
                          </Link>
                        </Info>
                      )}
                    </div>
                  </>
                }
              />
            </div>
          )}
          {showRCASettings && (
            <div className="mt-4">
              <InputLabelWithInherit
                disableInherit={isDefaultSettings || disabled}
                inherit={[category + "_restrict_associate_types"]}
                label="RCA relations types (empty for all)"
                input={
                  <SelectMultiple
                    disabled={disabled}
                    className="w-auto"
                    value={
                      value.restrict_associate_types
                        ?.split(",")
                        .filter((a) => a.trim()) || []
                    }
                    onChange={(e) =>
                      onChange({
                        ...value,
                        restrict_associate_types: e.join(","),
                      })
                    }
                    options={RCATypes.map((a) => ({
                      label: a.name,
                      value: `${a.code}`,
                    }))}
                  />
                }
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export const InputLabelWithInherit = (
  props: InputLabelProps & { inherit?: string[]; disableInherit?: boolean }
) => {
  const [inheritState, setInherit] = useRecoilState(InheritAtom);
  const inherit = props.inherit?.every((i) => inheritState[i]);

  return (
    <InputLabel
      {...props}
      className={(props.className || "") + " " + (inherit ? "opacity-50" : "")}
      input={inherit ? <></> : props.input || <></>}
      label={
        props.disableInherit ? (
          props.label
        ) : (
          <span
            onClick={() => {
              if (inherit) {
                const newInherit = { ...inheritState };
                props.inherit?.forEach((i) => {
                  newInherit[i] = !inherit;
                });
                setInherit(newInherit);
              }
            }}
          >
            {props.label}
            <InfoSmall>
              {" "}
              <Link
                onClick={() => {
                  if (!inherit) {
                    const newInherit = { ...inheritState };
                    props.inherit?.forEach((i) => {
                      newInherit[i] = !inherit;
                    });
                    setInherit(newInherit);
                  }
                }}
              >
                {inherit ? "Specify value" : "Inherit"}
              </Link>
            </InfoSmall>
          </span>
        )
      }
    />
  );
};
