import { ButtonConfirm } from "@atoms/button/confirm";
import { Table } from "@components/table";
import { WebAuthnCredential } from "@features/webauthn/types";
import { formatTime } from "@features/utils/dates";
import { XMarkIcon } from "@heroicons/react/24/solid";
type AuthenticationDevicesProps = {
  data: WebAuthnCredential[];
  loading: boolean;
  onDelete: (credentialId: number) => void;
  refetch: () => void;
};

export function AuthenticationDevices({
  data,
  onDelete,
  loading,
  refetch,
}: AuthenticationDevicesProps) {
  return (
    <Table
      loading={loading}
      showPagination={false}
      data={data}
      columns={[
        {
          title: "Authenticate",
          render: (item) => (item.type === 1 ? "Auth App" : "Access Key"),
        },
        {
          title: "IP Address",
          render: (item) => (
            <div className="flex items-center">{item.last_ip_address}</div>
          ),
        },
        {
          title: "First sign in",
          render: (item) => formatTime(item.created_at),
        },
        {
          title: "Last sign in",
          render: (item) => formatTime(item.last_sign_in),
        },
        {
          title: "",
          className: "justify-end",
          render: (item) => (
            <ButtonConfirm
              confirmTitle={"Do you want to continue ?"}
              confirmMessage={
                "You will not be able to use this device to connect to your account. Are you sure you want to delete it ?"
              }
              theme="danger"
              confirmButtonTheme="danger"
              size="sm"
              onClick={async () => {
                await onDelete(item.id);
                refetch();
              }}
            >
              <XMarkIcon className="h-5 w-5" />
            </ButtonConfirm>
          ),
        },
      ]}
    />
  );
}
