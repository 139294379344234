import { Button } from "@atoms/button/button";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { useCustomer } from "@features/customers/state/use-customer";
import { useCustomerStates } from "@features/customers/state/user-customer-states";
import _ from "lodash";
import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";

export const CustomerStatusUpdateModalAtom = atom<{
  open: boolean;
  comment: string;
  status: number | "disabled";
  customReviewDate?: Date;
  customer_id: string;
}>({
  key: "CustomerStatusUpdateModalAtom",
  default: {
    open: false,
    comment: "",
    status: 3,
    customer_id: "",
  },
});

export const CustomerStatusUpdateModal = () => {
  const [modal, setModal] = useRecoilState(CustomerStatusUpdateModalAtom);

  return (
    <Modal
      open={modal.open}
      onClose={() => setModal({ ...modal, open: false })}
    >
      <CustomerStatusUpdateModalContent />
    </Modal>
  );
};

const CustomerStatusUpdateModalContent = () => {
  const [modal, setModal] = useRecoilState(CustomerStatusUpdateModalAtom);
  const { states } = useCustomerStates();
  const { update, disable, customer, loading } = useCustomer(modal.customer_id);

  useEffect(() => {
    if (modal.status !== 4) {
      setModal({ ...modal, customReviewDate: undefined });
    }
  }, [modal.status]);

  return (
    <ModalContent title={"Update customer review"}>
      <InputLabel
        label="Customer status"
        input={
          <Select
            value={modal.status}
            onChange={(e) =>
              setModal({
                ...modal,
                status:
                  e.target.value === "disabled"
                    ? "disabled"
                    : parseInt(e.target.value),
              })
            }
          >
            {Object.keys(states)
              .filter((a) => parseInt(a) >= 3 || a === "disabled")
              .map((state) => (
                <option value={state} key={state}>
                  {state === "disable"
                    ? customer?.details?.customer?.disabled
                      ? "Enable"
                      : "Disable"
                    : _.capitalize(states[state as any])}
                </option>
              ))}
          </Select>
        }
      />
      <InputLabel
        className="mt-2"
        label="Review"
        input={
          <Input
            value={modal.comment}
            onChange={(e) => setModal({ ...modal, comment: e.target.value })}
            multiline
          />
        }
      />
      {modal.status === 4 && ( // if status "Passed"
        <InputLabel
          className="mt-2"
          label="Custom next review date (Optional)"
          input={
            <InputDate
              value={modal.customReviewDate}
              className="z-50"
              onChange={(e) =>
                setModal({ ...modal, customReviewDate: e || undefined })
              }
            />
          }
        />
      )}
      <Button
        className="mt-4"
        disabled={loading}
        theme="default"
        onClick={() => setModal({ ...modal, open: false })}
      >
        Cancel
      </Button>
      <Button
        className="mt-4 float-right"
        loading={loading}
        disabled={!modal.comment || !modal.status}
        onClick={async () => {
          if (typeof modal.status === "number") {
            await update(modal.status, modal.comment, modal.customReviewDate);
          } else if (
            modal.status === "disabled" &&
            !customer?.details?.customer?.disabled
          ) {
            await disable();
          } else if (
            modal.status === "disabled" &&
            customer?.details?.customer?.disabled
          ) {
            await disable(false);
          }
          setModal({
            ...modal,
            comment: "",
            status: 3,
            customReviewDate: undefined,
            open: false,
          });
        }}
      >
        Update customer status
      </Button>
    </ModalContent>
  );
};
