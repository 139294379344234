import { Base } from "@atoms/text";
import { useEffect, useState } from "react";
import { Input } from "./input-text";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Button } from "@atoms/button/button";
import { PlusIcon } from "@heroicons/react/24/outline";

type InputTextArrayProps = {
  value: string[];
  onChange: (value: string[]) => void;
};

export function InputTextArray({ value, onChange }: InputTextArrayProps) {
  const [editing, setEditing] = useState<number>();
  useEffect(() => {
    if (editing == undefined) {
      const empties = value.filter((content) => content.trim() === "");
      if (empties.length > 0) {
        onChange(value.filter((content) => content.trim() !== ""));
      }
    }
  }, [editing]);
  return (
    <div className="flex flex-col gap-2">
      {value.length === 0 && (
        <Base className="h-9 content-center">No regex for this category</Base>
      )}
      {value.map((content, index) => (
        <div key={index} className="flex gap-2 items-center relative">
          {editing !== index && (
            <div
              className="h-9 content-center px-3 border border-transparent bg-slate-100 cursor-pointer"
              onClick={() => {
                if (editing !== index) {
                  setEditing(index);
                }
              }}
            >
              <Base className="text-sm">{content}</Base>
            </div>
          )}
          {editing === index && (
            <div className="flex gap-2 items-center w-full">
              <Input
                autoFocus={true}
                value={content}
                onBlur={() => {
                  if (editing === index) {
                    setEditing(undefined);
                  }
                }}
                onChange={(e) => {
                  const newValue = [...value];
                  newValue[index] = e.target.value;
                  onChange(newValue);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setEditing(undefined);
                  }
                }}
              />
              <XMarkIcon
                onMouseDown={() => {
                  onChange(value.filter((_, i) => i !== index));
                }}
                className="h-6 w-6 cursor-pointer hover:opacity-50 bg-blue-700 p-0.5 text-white rounded-full"
              />
            </div>
          )}
        </div>
      ))}
      <Button
        onClick={() => {
          const newValue = [...value, ""];
          onChange(newValue);
          setEditing(newValue.length - 1);
        }}
        className="w-max flex gap-2"
      >
        <PlusIcon className="h-6 w-6" />
        <span>New</span>
      </Button>
    </div>
  );
}
