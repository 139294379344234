import { Button } from "@atoms/button/button";
import { Base, SectionSmall } from "@atoms/text";
import { CustomersApiClient } from "@features/customers/api-client/api-client";
import { useCustomer } from "@features/customers/state/use-customer";
import { UpdateCustomersRequest } from "@features/customers/types";
import { ROUTES } from "@features/routes";
import {
  ArrowDownOnSquareIcon,
  ExclamationCircleIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/24/outline";
import _ from "lodash";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CustomerEditionModalAtom } from ".";

export const TestAndSave = ({
  disabled,
  customerId,
  form,
  setForm,
}: {
  disabled?: boolean;
  customerId?: string;
  form: UpdateCustomersRequest;
  setForm: (form: UpdateCustomersRequest) => void;
}) => {
  const navigate = useNavigate();

  const { refresh } = useCustomer(customerId || "");

  const setEditModal = useSetRecoilState(CustomerEditionModalAtom);
  const [loading, setLoading] = useState(false);
  const [dryRun, setDryRun] = useState({
    errors: [],
    warnings: [],
  });

  const save = useCallback(
    async (addAnother = false) => {
      setLoading(true);
      if (["F", "N"].includes(form.customers[0].account_type)) {
        form.customers[0].account_number = form.customers[0].external_id;
      }
      form.customers[0].contacts = (form.customers[0].contacts || "")
        .split(/,|;/g)
        .map((contact) => {
          const vals = (contact.trim() || "").replace(/ +/gm, " ").split(" ");
          if (vals.length > 1) {
            const email = (contact.trim() || "").match(/<(.*?)>$/)?.[1];
            const name = vals.splice(0, vals.length - 1).join(" ");
            if (!email) {
              return "";
            }
            return name + " <" + (email || "") + ">";
          } else {
            return vals[0];
          }
        })
        .filter((a) => a)
        .join(", ");
      try {
        const data = await CustomersApiClient.updateCustomer(form);
        if (data.status === "fail") {
          setDryRun({
            errors:
              data.detail[0]?.errors?.map((error: any) => error.error) ?? [],
            warnings:
              data.detail[0]?.warnings?.map((warning: any) => warning.error) ??
              [],
          });
          setLoading(false);
          return;
        } else if (data?.added?.[0]?.customer_id) {
          toast.success("Record saved successfully");
          if (!customerId) {
            //Created, move to the created used and reset the form
            setEditModal({
              open: false,
              customer: undefined,
            });
            setTimeout(() => {
              navigate(
                ROUTES.CustomerView.replace(":id", data?.added[0]?.customer_id)
              );
              if (addAnother) {
                setTimeout(() => {
                  setEditModal({
                    open: true,
                    customer: undefined,
                  });
                }, 500);
              }
            }, 1000); //A timeout is needed after creating the customer in backend
          } else {
            //Edited, reload the customer view
            await refresh();
            setEditModal({
              open: false,
              customer: undefined,
            });
          }
        } else if (data.status !== "success") {
          toast.error("Unexpected error occurred, please try again later");
          setLoading(false);
          return;
        }

        // switch to save record mode
        setForm({
          ...form,
          verify_input: false,
        });
        setDryRun({
          errors: [],
          warnings:
            data.detail?.[0]?.warnings
              ?.filter(
                (w: any) => !(w.error === "external_id_exists" && customerId)
              )
              ?.map((warning: any) => warning.error) ?? [],
        });

        setLoading(false);
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occured, please try again later");
        setLoading(false);
        return;
      }
    },
    [form, customerId, navigate, refresh, setEditModal, setForm]
  );

  return (
    <>
      <div className="relative w-full">
        {(dryRun.errors.length > 0 || dryRun.warnings.length > 0) && (
          <div>
            <SectionSmall className="mb-2">Record verification</SectionSmall>
            {dryRun.errors.map((error: string, index) => {
              return (
                <div key={error + index} className="flex flex-row items-center">
                  <ExclamationCircleIcon className="w-5 inline-block mr-1 text-red-500" />
                  <Base className="ml-2">
                    {_.capitalize((error || "")?.replace(/_/gm, " "))}
                  </Base>
                </div>
              );
            })}
            {dryRun.warnings.map((error: string, index) => {
              return (
                <div key={error + index} className="flex flex-row items-center">
                  <ShieldExclamationIcon className="w-5 inline-block mr-1 text-orange-500" />
                  <Base className="ml-2">
                    {_.capitalize((error || "")?.replace(/_/gm, " "))}
                  </Base>
                </div>
              );
            })}
          </div>
        )}
        <div className="absolute right-2 text-end">
          {form.verify_input && (
            <Button
              disabled={disabled}
              theme="outlined"
              className="mt-4"
              loading={loading}
              onClick={() => {
                save();
              }}
            >
              <ArrowDownOnSquareIcon className="w-5 inline mr-2" />
              Verify and save
            </Button>
          )}
          {!form.verify_input && (
            <div className="mt-4 flex flex-row items-center">
              <Button
                className={customerId ? "" : "rounded-r-none"}
                loading={loading}
                onClick={() => {
                  save();
                }}
              >
                <ArrowDownOnSquareIcon className="w-5 inline mr-2" />
                Save
              </Button>
              {!customerId && (
                <Button
                  className="-ml-px rounded-l-none"
                  loading={loading}
                  theme="outlined"
                  onClick={() => {
                    save(true);
                  }}
                >
                  Save and add another
                </Button>
              )}
            </div>
          )}
          <div className="h-8"></div>
        </div>
      </div>
    </>
  );
};
