import _ from "lodash";
import {
  Question,
  QuestionUpdate,
  Rule,
  ScenarioType,
  ScenarioUpdateType,
  TriggerDefinition,
  TriggerState,
  TriggerStates,
  TriggerType,
  TriggerTypes,
} from "./types";

export const SCENARIOS_LANGUAGES: {
  [key: string]: string;
} = {
  "ar-AE": "Arabic",
  "cs-CZ": "Czech",
  "da-DK": "Danish",
  "de-DE": "Deutsch",
  "en-US": "English",
  "es-ES": "Español",
  "fi-FI": "Finnish",
  "fr-FR": "Français",
  "id-ID": "Indonesian",
  "it-IT": "Italian",
  "nl-NL": "Nederlands",
  "ro-RO": "Romanian",
  "ru-RU": "Russian",
  "sv-SE": "Swedish",
  "tr-TR": "Turkish",
};

export const QUESTION_TYPES = [
  "information",
  // "verification",
  "identity",
  "face",
  // "antibot",
  "file_upload",
];

export const SCENARIOS_SCORING_SEVERITY: { [key: string]: string } = {
  eliminatory: "Eliminatory",
  require_human_review: "Require human review",
  flag: "Flag in scoring",
  consider: "Consider in scoring",
  nothing: "Ignore",
};

export const SCENARIOS_SCORING_FACE_CONTROLS: { [key: string]: string } = {
  "face.detected": "Face detected",

  "face.quality": "Face quality",
  "face.quality.brightness": "Brightness",
  "face.quality.sharpness": "Sharpness",

  "face.imageIntegrity": "Face integrity",
  "face.imageIntegrity.liveness": "Liveness",
  "face.imageIntegrity.three_d": "3D",
  "face.imageIntegrity.speech": "Speech",
  "face.imageIntegrity.spoof": "Spoof",
  "face.imageIntegrity.spoof.mask": "Mask",
  "face.imageIntegrity.spoof.generated": "Generated",
  "face.imageIntegrity.single": "Single face",
  "face.imageIntegrity.another_identity": "Another identity",
  "face.imageIntegrity.colors": "Image integrity colors (obselete)",

  "face.media": "Face media",
  "face.media.metadata": "Metadata",
  "face.media.live": "Live",
  "face.media.virtual": "Virtual Camera",
  "face.media.reused": "Reused",

  "face.matched": "Faces matched",
  "face.matched.similarity": "Similarity",
  "face.matched.quality": "Faces match quality",
  "face.matched.quality.brightness": "Brightness",
  "face.matched.quality.sharpness": "Sharpness",
  "face.matched.overmatch": "Not copies",
  "face.matched.single": "Single face",
};

export const SCENARIOS_SCORING_ID_CONTROLS: { [key: string]: string } = {
  imageIntegrity: "Image Integrity (obselete)",
  "imageIntegrity.colors": "Document is coloured (obselete)",

  visualAuthenticity: "Visual Authenticity",
  "visualAuthenticity.specimen": "Document is not a specimen",
  "visualAuthenticity.face": "Face on document",
  "visualAuthenticity.face.detected": "Face was detected",
  "visualAuthenticity.face.age": "Face age isn't younger than expected",
  "visualAuthenticity.face.gender": "Gender isn't different than expected",
  "visualAuthenticity.face.pose": "Face pose is face to camera and neutral",

  dataValidation: "Data Validation",
  "dataValidation.mrz": "MRZ",
  "dataValidation.mrz.checksum": "Check the MRZ checksum",
  "dataValidation.emitDate": "Issuing date",
  "dataValidation.expirationDate": "Expiration date",
  "dataValidation.birthDate": "Birth date",
  "dataValidation.documentType": "Document type",

  "document.media": "Document media",
  "document.media.metadata": "Metadata",
  "document.media.live": "Live",
  "document.media.ai": "AI",
  "document.media.virtual": "Virtual Camera",
  "document.media.reused": "Reused",

  "documentType.accepted": "Document type accepted"
};

export const QUESTION_UPLOAD_FILE_MODELS: { [key: string]: string } = {
  hu_address_card: "Hungary Address Card",
};

export const QUESTION_UPLOAD_FILE_FRAME_TYPES: { [key: string]: string } = {
  noframe: "Full screen / No Frame",
  "32frame": "3:2 Frame",
};

export const getAvailableLanguages = (scenario: ScenarioType) => {
  return scenario.questions?.at(0)?.description_localized
    ? Object.keys(scenario.questions.at(0)!.description_localized)
    : [scenario.language];
};

export const transformScenarioUpdate = (input: ScenarioType) => {
  const update = { ...input, questions: [] } as ScenarioUpdateType;
  update.questions = input.questions.map((question) =>
    transformQuestionUpdate(question, input.languages)
  );

  return {
    ...update,
  } as ScenarioUpdateType;
};

export const transformQuestionUpdate = (
  question: Question,
  languagues: string[]
) => {
  const qUpdate = { ...question, description_localized: [] } as QuestionUpdate;
  qUpdate.description_localized = languagues.map((language) => ({
    language,
    description: question.description_localized[language] ?? "",
  }));

  if (question.type === "information") {
    qUpdate.information_data = {
      possible_answers: question.possible_answers,
      should_not_contain: question.should_not_contain,
    };
  }

  if (question.type === "face") {
    qUpdate.face_data = {
      face_number: question.face_number,
    };
  }

  if (question.type === "file_upload") {
    qUpdate.upload_data = {
      max_pages: question.max_pages,
      frame_type: question.frame_type,
      extract_models: question.extract_models,
    };
  }
  return qUpdate;
};

export const RULE_TRIGGERS = {
  [TriggerTypes.SetToPendingTrigger]: "set to pending",
  [TriggerTypes.IgnoreTrigger]: "ignore",
};

export const RULE_STATES = {
  [TriggerStates.Error]: "Error",
  [TriggerStates.Undefined]: "Undefined",
  [TriggerStates.Warning]: "Warning",
};

export const ruleToTriggerDefinition = (rules: Rule[]) => {
  const triggerDefinition: TriggerDefinition = {
    ignore_triggers: {
      warning: [],
      error: [],
      undefined: [],
    },
    set_to_pending_triggers: {
      warning: [],
      error: [],
      undefined: [],
    },
  };

  rules.forEach((rule) => {
    const trigger = triggerDefinition[rule.trigger];
    rule.states.forEach((state) => {
      trigger[state] = [...trigger[state], rule.identifier];
    });
  });

  return triggerDefinition;
};

export const triggerDefinitionToRuleArray = (
  triggerDefinition: TriggerDefinition
) => {
  const rules: Rule[] = [];
  Object.keys(triggerDefinition).forEach((trigger) => {
    const type = trigger as TriggerType;
    if (!RULE_TRIGGERS[type]) {
      return;
    }
    const idStates: { [key: string]: TriggerState[] } = {};

    Object.keys(triggerDefinition[type]).forEach((key) => {
      const state = key as TriggerState;
      triggerDefinition[type][state].forEach((id) => {
        if (!idStates[id]) {
          idStates[id] = [];
        }
        idStates[id].push(state);
      });
    });
    Object.keys(idStates).forEach((id) => {
      rules.push({
        identifier: id,
        states: _.uniq(idStates[id]),
        trigger: type,
      });
    });
  });
  return rules;
};
