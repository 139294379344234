import { Button } from "@atoms/button/button";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { ModalImage } from "@components/modal-image";
import { useTotp } from "@features/totp/state/useTOTP";
import { copyToClipboard } from "@features/utils";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import toast from "react-hot-toast";

type ModalTotpProps = {
  emailLogin: string;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
};

function ModalTotp(props: ModalTotpProps) {
  const { emailLogin, isOpen, setOpen } = props;
  const { useTotpGenerateQrCode, setTotpVerify } = useTotp();
  const { data } = useTotpGenerateQrCode(emailLogin);

  const [userCode, setUserCode] = useState("");

  const onSubmitTotp = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const response = await setTotpVerify({
      userCode,
      secret: data?.secret,
    });
    if (response) {
      toast.success("Your account is authenticate with success!");
      setOpen(false);
      await props.refetch();
    }
  };

  return (
    <div className="flex flex-row justify-between items-center">
      <Modal open={isOpen} onClose={() => setOpen(false)}>
        <ModalContent>
          <h1 className="text-2xl text-justify text-blue-600">
            Enabled Authenticator Support
          </h1>
          {data?.secret && (
            <p className="text-xm mt-2">
              Authenticator Secret Code:{" "}
              <span
                className="bg-slate-100 hover:bg-slate-300 text-xs rounded-md py-1 px-2 cursor-pointer"
                onClick={() => copyToClipboard(data.secret)}
              >
                {data.secret}
              </span>
            </p>
          )}
          <ModalImage
            small={`data:image/jpeg;base64,${data?.qrCode}`}
            large={`data:image/jpeg;base64,${data?.qrCode}`}
            alt="QrCode"
            className="mt-2 w-24"
          />
          <ol className="mt-2 text-xs">
            <li>
              1. Install an Authenticator app on your mobile device if you don't
              already have on.
            </li>
            <li>
              2. Scan QR Code with the authenticator (or tap it in mobile
              browser)
            </li>
            <li>
              3. Please write down or print a copy of the 16-digit secret code
              and put it in a safe place
            </li>
            <li>
              4. If your phone gets lost, stolen or erased, you will need this
              code to link Algoreg to a new authenticator app install once again
            </li>
            <li>
              5. Do not share it with anyone. Be aware of phishing scams. We
              will never ask you for this key
            </li>
          </ol>
          <p className="text-xs mt-1">
            <b>
              Once an authenticator app is enabled, all other 2FA methods will
              not be accepted.
            </b>
          </p>
          <form action="" onSubmit={onSubmitTotp}>
            <div className="flex items-center w-full bg-slate-100 mt-2 p-4">
              <div className="flex justify-center items-center">
                <DevicePhoneMobileIcon className="w-16 h-16 my-2" />
              </div>
              <div className="flex-col">
                <p className="text-xs">
                  <b>
                    Enter verification code provided by your authentication app.
                  </b>
                </p>
                <Input
                  className="my-2 w-full block text-sm font-medium text-gray-700 dark:text-slate-400 whitespace-nowrap overflow-hidden text-ellipsis"
                  placeholder="000000"
                  value={userCode}
                  onChange={(e) => setUserCode(e.target.value)}
                  minLength={6}
                  maxLength={6}
                />
              </div>
            </div>
            <div className="flex justify-between mt-5">
              <Button theme="default" size="sm" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button size="sm" type="submit">
                Enabled
              </Button>
            </div>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ModalTotp;
