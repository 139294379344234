import { is2XX, useFetch } from "@features/utils";
import { ReportingSettingsType } from "../types";

export class ReportingApiClient {
  static getReportingSettings = async () => {
    const response = await useFetch(`/web/reporting/settings`, {
      method: "GET",
    });
    const data = await response.json();
    return data as ReportingSettingsType;
  };

  static setReportingSettings = async (settings: ReportingSettingsType) => {
    const response = await useFetch(`/web/reporting/settings`, {
      method: "POST",
      body: JSON.stringify(settings),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to save reporting settings");
    }
  };

  static testReportingSettings = async (index: number) => {
    const response = await useFetch(
      `/web/reporting/send-test-report?index=${index}`,
      {
        method: "GET",
      }
    );
    if (!is2XX(response.status)) {
      throw new Error("Failed to send report email test");
    }
  };
}
