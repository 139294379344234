import { ReactNode, useCallback, useEffect, useState } from "react";
import "./index.scss";
import { Column, Pagination, RenderedTable } from "./table";

type PropsType<T> = {
  columns: Column<T>[];
  data: T[];
  scrollable?: boolean;
  rowIndex?: string;
  total?: number;
  page?: number;

  cellClassName?: (row: T) => string;
  tableClassName?: string;
  className?: string;
  options?: {
    exportCsv?: {
      isActive?: boolean;
      fileName?: string;
      fetchData?: (pagination: Pagination) => Promise<T[]>;
    };
  };
  loading?: boolean;
  onRequestData?: (pagination: Pagination) => Promise<void>;
  onClick?: (item: T, e: MouseEvent) => void;
  onSelect?:
    | {
        icon?: (args: { className?: string }) => ReactNode;
        label: string | ReactNode;
        callback: (items: T[]) => void;
      }[]
    | ((items: T[]) => void);
  showPagination?: boolean;
  initialPagination?: Pick<
    Pagination,
    "order" | "orderBy" | "page" | "perPage"
  >;
};

export function Table<T>({
  columns,
  data,
  rowIndex,
  total,
  page,
  scrollable,
  showPagination = true,
  initialPagination,
  onRequestData,
  onClick,
  onSelect,
  loading,
  cellClassName,
  tableClassName,
  className,
  options,
}: PropsType<T>) {
  const [pagination, setPagination] = useState<Pagination>({
    total: total || 0,
    page: initialPagination?.page || 1,
    perPage: initialPagination?.perPage || 10,
    orderBy: initialPagination?.orderBy,
    order: initialPagination?.order,
  });
  const [internalLoading, setLoading] = useState(false);

  const resolve = useCallback(async () => {
    setLoading(true);
    if (onRequestData) await onRequestData(pagination);
    setLoading(false);
  }, [onRequestData, setLoading, pagination]);

  /* react-hooks/exhaustive-deps issues */
  (useEffect as any)(() => {
    if (total !== pagination.total)
      setPagination({
        ...pagination,
        page: page || pagination.page,
        total: total || 0,
      });
  }, [total, setPagination]);

  /* react-hooks/exhaustive-deps issues */
  (useEffect as any)(() => {
    resolve();
  }, [
    pagination.perPage,
    pagination.page,
    pagination.order,
    pagination.orderBy,
  ]);

  return (
    <RenderedTable
      options={options}
      columns={columns}
      data={data}
      rowIndex={rowIndex}
      pagination={pagination}
      showPagination={showPagination}
      scrollable={scrollable}
      onClick={onClick}
      onSelect={onSelect}
      loading={loading || internalLoading}
      cellClassName={cellClassName}
      tableClassName={tableClassName}
      className={className}
      onChangeOrder={(columnIndex, direction) => {
        setPagination({
          ...pagination,
          orderBy: columnIndex,
          order: direction,
        });
      }}
      onChangePage={(page) => {
        setPagination({
          ...pagination,
          page,
        });
      }}
      onChangePageSize={(size) => {
        setPagination({
          ...pagination,
          perPage: size,
        });
      }}
    />
  );
}
