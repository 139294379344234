import { Page } from "@atoms/layout/page";
import { Info, Section, Title } from "@atoms/text";
import AlertsExport from "./alerts-export";
import CustomersExport from "./customers-export";
import SessionsExport from "./sessions-export";
import { useHasAccess } from "@features/auth/state/use-access";
import { ReportingSettingsModalAtom } from "./reporting-settings-modal";
import { useSetRecoilState } from "recoil";
import { Button } from "@atoms/button/button";
import ConfigurationsExport from "./configurations-export";
import MetricsHistoryExport from "./metrics-history-export";
import LogsAndNotifExport from "./logs-and-notif-export";

export default function ReportingPage() {
  const hasAccess = useHasAccess();
  const setOpenReportingSettingsModal = useSetRecoilState(
    ReportingSettingsModalAtom
  );

  return (
    <Page>
      <Title>Reports</Title>
      <Info className="block">
        Send reports to your email address to keep track of your Algoreg
        activity.
      </Info>

      {!hasAccess("AGENT_EDIT") && (
        <Info className="block mt-4 mb-8">
          You don't have access to this feature. Please contact your admin to
          get access.
        </Info>
      )}

      {hasAccess("AGENT_EDIT") && (
        <>
          <Button
            className="mb-6 mt-4"
            onClick={() =>
              setOpenReportingSettingsModal({
                open: true,
              })
            }
            size="md"
          >
            Manage email reports
          </Button>
        </>
      )}

      <Title>Audit</Title>
      <Info className="block">
        Export auditable data from your Algoreg account.
      </Info>

      {hasAccess("RISK_SETTINGS_WRITE") && (
        <>
          <Section className="mt-4">Customers export</Section>
          <CustomersExport />
        </>
      )}

      {hasAccess("POST_FILTERING_UPDATE") && (
        <>
          <Section className="mt-4">Alerts export</Section>
          <AlertsExport />
        </>
      )}

      {hasAccess("SCENARIO_UPDATE") && (
        <>
          <Section className="mt-4">Vid sessions export</Section>
          <SessionsExport />
        </>
      )}

      {/* From this line to the bottom, 'access rights' are inaccurate, temporary placeholders. TODO: create proper access rights */}
      {hasAccess("AGENT_EDIT") && (
        <>
          <Section className="mt-4">Metrics history export</Section>
          <MetricsHistoryExport />
        </>
      )}

      {hasAccess("AGENT_EDIT") && (
        <>
          <Section className="mt-4">Logs and notifs export</Section>
          <LogsAndNotifExport />
        </>
      )}

      {hasAccess("AGENT_EDIT") && (
        <>
          <Section className="mt-4">Configurations export</Section>
          <ConfigurationsExport />
        </>
      )}
    </Page>
  );
}
