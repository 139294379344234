import { Tag } from "@atoms/badge/tag";
import { SessionAlertStatus } from "@features/sessions/types";
import { LABEL_TYPE_COLORS } from "@features/sessions/utils";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  NoSymbolIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

type AlertTagProps = {
  score?: number;
  className?: string;
  state: SessionAlertStatus;
};

export function AlertTag({ score, className = "", state }: AlertTagProps) {
  const scoring = useMemo(() => {
    let alertScoring = {
      score: -1,
      status: "UNDEFINED",
      color: LABEL_TYPE_COLORS.UNDEFINED,
      icon: <NoSymbolIcon className="h-4 w-4" />,
    };
    if (score === undefined) {
      score = 0;
    }

    if (state === SessionAlertStatus.Failed) {
      alertScoring = {
        score: score,
        status: "FAILED",
        color: LABEL_TYPE_COLORS.NEGATIVE,
        icon: <XCircleIcon className="h-5 w-5 opacity-75" />,
      };
    }

    if (state === SessionAlertStatus.Consider) {
      alertScoring = {
        score: score,
        status: "CONSIDER",
        color: LABEL_TYPE_COLORS.NEUTRAL,
        icon: <ExclamationTriangleIcon className="h-4 w-4" />,
      };
    }

    if (state === SessionAlertStatus.Success) {
      alertScoring = {
        score: score,
        status: "PASSED",
        color: LABEL_TYPE_COLORS.POSITIVE,
        icon: <CheckCircleIcon className="h-4 w-4 opacity-75" />,
      };
    }
    return alertScoring;
  }, [state]);
  return (
    <Tag
      noColor
      className={twMerge(
        `flex flex-row space-x-1 px-1.5 py-1 text-white items-center ${scoring.color}`,
        className
      )}
    >
      {scoring.icon}
      <span>{scoring.status}</span>
    </Tag>
  );
}
