import Avatar from "@atoms/avatar/avatar";
import { Button } from "@atoms/button/button";
import { GetStartedCard } from "@atoms/card/get-started";
import { Title } from "@atoms/text";
import { Role } from "@components/roles-manager";
import { Table } from "@components/table";
import { useAgents } from "@features/agents/state/use-agents";
import { AgentType } from "@features/agents/types";
import { useHasAccess } from "@features/auth/state/use-access";
import { Page } from "@atoms/layout/page";
import { useSetRecoilState } from "recoil";
import { AgentEditionModalAtom } from "./edition-modal";
import { Tag } from "@atoms/badge/tag";
import { useAuth } from "@features/auth/state/hooks";
import { CheckIcon } from "@heroicons/react/20/solid";
import _ from "lodash";

export const UsersManagementPage = () => {
  const { members: data, refresh } = useAgents();
  const { clientId } = useAuth();

  const setEditionModalState = useSetRecoilState(AgentEditionModalAtom);
  const hasAccess = useHasAccess();

  return (
    <Page>
      {hasAccess("AGENT_EDIT") && (
        <Button
          onClick={() => {
            setEditionModalState({ open: true, id: null });
          }}
          className="float-right"
          theme="primary"
          size="sm"
        >
          Create user
        </Button>
      )}

      <Title>Users</Title>

      <div className="my-4">
        <GetStartedCard
          show={data.length === 1}
          title={<>Invite the rest of your team</>}
          text={
            <>
              You are the only one in this Algoreg instance. On this page you
              can add and manage collaborators.
            </>
          }
        />
      </div>

      <Table
        showPagination={false}
        data={_.sortBy(data, "name")}
        columns={[
          {
            title: "Name",
            render: (item) => (
              <div className="flex items-center">
                <Avatar
                  size={5}
                  avatar={item.avatar}
                  fallback={item.name}
                  className="h-5 w-5 mr-2"
                />{" "}
                {item.name}
              </div>
            ),
          },
          {
            title: "Email",
            render: (item) => item.email_login,
          },
          ...((hasAccess("AGENT_EDIT") && [
            {
              title: "2FA",
              render: (item: AgentType) => (
                <>
                  {item.has_two_fa && (
                    <CheckIcon className="w-6 h-auto text-green-600" />
                  )}
                </>
              ),
            },
            {
              title: "Roles",
              thClassName: "w-64",
              render: (item: AgentType) => (
                <>
                  <div>
                    {item?.clients
                      ?.find((c) => c.id === clientId)
                      ?.roles.map((role) => (
                        <Role code={role.code} key={role.code} />
                      ))}
                  </div>
                </>
              ),
            },
            {
              title: "Review groups",
              thClassName: "w-64",
              render: (item: AgentType) => (
                <>
                  <div>
                    {(
                      item?.clients?.find((c) => c.id === clientId)
                        ?.review_groups || []
                    ).map((group, i) => (
                      <Tag key={i}>{group}</Tag>
                    ))}
                  </div>
                </>
              ),
            },
            {
              title: "Actions",
              headClassName: "justify-end",
              className: "text-right",
              thClassName: "w-24",
              render: (item: AgentType) => (
                <Button
                  theme="outlined"
                  size="sm"
                  onClick={() =>
                    setEditionModalState({
                      open: true,
                      id: item.clients.find((c) => c.id === clientId)
                        ?.agent_id!,
                    })
                  }
                >
                  Modify
                </Button>
              ),
            },
          ]) ||
            []),
        ]}
        onRequestData={async () => {
          refresh();
        }}
      />
    </Page>
  );
};
