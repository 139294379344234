import { Button } from "@atoms/button/button";
import Select from "@atoms/input/input-select";
import SelectMultiple from "@atoms/input/input-select-multiple";
import {
  Rule,
  RuleState,
  RuleTrigger,
  TriggerStates,
  TriggerTypes,
} from "@features/scenarios/types";
import { RULE_STATES, RULE_TRIGGERS } from "@features/scenarios/utils";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

type RulesProps = {
  rules: Rule[];
  onChange: (newRules: Rule[]) => void;
  options: { [key: string]: string };
};

const getKey = (rule: Rule) => {
  return rule.identifier + rule.trigger;
};

export function Rules({ rules, options, onChange }: RulesProps) {
  const [order, setOrder] = useState<{ [key: string]: number }>({});

  const changeRule = (rule: Rule, newRule: Rule) => {
    const index = rules.indexOf(rule);
    const newRules = _.cloneDeep(rules);
    newRules[index] = newRule;

    const newOrder = _.cloneDeep(order);
    const oldOrder = newOrder[getKey(rule)];
    delete newOrder[getKey(rule)];
    if (!newOrder[getKey(newRule)]) {
      newOrder[getKey(newRule)] = oldOrder;
    }
    setOrder(newOrder);

    onChange(newRules);
  };

  useEffect(() => {
    const newOrder = _.cloneDeep(order);
    let hasChanged = false;
    let nextNumber =
      Object.keys(newOrder).length > 0
        ? Math.max(...Object.keys(newOrder).map((key) => newOrder[key])) + 1
        : 1;
    const keyToDelete: string[] = [];
    Object.keys(newOrder).forEach((key) => {
      if (!rules.find((rule) => getKey(rule) === key)) {
        keyToDelete.push(key);
      }
    });
    keyToDelete.forEach((key) => {
      delete newOrder[key];
    });
    rules.forEach((rule) => {
      if (!newOrder[getKey(rule)]) {
        newOrder[getKey(rule)] = nextNumber++;
        hasChanged = true;
      }
    });
    if (hasChanged) {
      setOrder(newOrder);
    }
  }, [rules, order]);
  return (
    <div className="flex flex-col gap-2">
      {rules
        .sort((a, b) => order[getKey(a)] - order[getKey(b)])
        .map((rule, index) => (
          <div
            key={getKey(rule)}
            className="flex gap-2 items-center bg-slate-100 rounded px-4 py-2"
          >
            <span>{index + 1}.</span>

            <div className="flex flex-wrap gap-2 flex-1 items-center">
              <span>When</span>
              <Select
                className="w-max"
                value={rule.identifier}
                onChange={(e) => {
                  const newRule = _.cloneDeep(rule);
                  newRule.identifier = e.target.value;
                  changeRule(rule, newRule);
                }}
              >
                {Object.keys(options).map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </Select>
              <span>is</span>
              <div className="w-64">
                <SelectMultiple
                  value={rule.states}
                  options={Object.keys(RULE_STATES).map((state) => ({
                    label: RULE_STATES[state as RuleState],
                    value: state,
                  }))}
                  onChange={(values) => {
                    const newRule = _.cloneDeep(rule);
                    newRule.states = values as RuleState[];
                    changeRule(rule, newRule);
                  }}
                />
              </div>
              <span>then</span>
              <Select
                className="w-max"
                value={rule.trigger}
                onChange={(e) => {
                  const newRule = _.cloneDeep(rule);
                  newRule.trigger = e.target.value as RuleTrigger;
                  changeRule(rule, newRule);
                }}
              >
                {Object.keys(RULE_TRIGGERS).map((trigger) => (
                  <option key={trigger} value={trigger}>
                    {RULE_TRIGGERS[trigger as RuleTrigger]}
                  </option>
                ))}
              </Select>
            </div>
            <XMarkIcon
              onClick={() => {
                onChange(rules.filter((tmp) => tmp != rule));
              }}
              className="h-6 w-6 cursor-pointer hover:opacity-50 bg-blue-700 p-0.5 text-white rounded-full"
            />
          </div>
        ))}
      <Button
        className="w-max flex gap-2"
        size={"md"}
        onClick={() => {
          const id = Object.keys(options).find(
            (id) => !rules.find((rule) => rule.identifier === id)
          );
          if (!id) {
            toast.error(
              "Rule cannot be created, there are too many rules, a rule with each possible control has already been created..."
            );
            return;
          }
          onChange([
            ...rules,
            {
              identifier: id,
              states: [TriggerStates.Error],
              trigger: TriggerTypes.SetToPendingTrigger,
            },
          ]);
        }}
      >
        <PlusIcon className="h-6 w-6" />
        <span>New Rule</span>
      </Button>
    </div>
  );
}
