import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import Select from "@atoms/select";
import { Info } from "@atoms/text";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { UpdateCustomersRequest } from "@features/customers/types";
import { CUSTOMER_TYPES_LABELS } from "@features/customers/utils";
import _ from "lodash";

export const CustomerIdentification = ({
  disabled,
  form,
  setForm,
}: {
  disabled?: boolean;
  form: UpdateCustomersRequest;
  setForm: (form: UpdateCustomersRequest) => void;
}) => {
  const { reviewGroups } = useReviewGroups(
    form.customers[0].review_groups
      .split(/,|;/g)
      .map((a) => a.trim())
      .filter((a) => a)
  );
  return (
    <>
      <div className="flex flex-row mt-4">
        <div className="w-1/6">
          <InputLabel
            label={"Customer type"}
            input={
              <Select
                disabled={disabled}
                value={form.customers[0].account_type}
                onChange={(e) => {
                  setForm({
                    ...form,
                    customers: [
                      {
                        ...form.customers[0],
                        account_number:
                          e.target.value === "S" || e.target.value === "U"
                            ? form.customers[0].external_id
                            : "",
                        account_type: e.target.value as any,
                      },
                    ],
                  });
                }}
              >
                <option value={""} disabled>
                  Select customer type
                </option>
                {_.uniq(["N", "F", form.customers[0].account_type || "N"]).map(
                  (key) => (
                    <option key={key} value={key}>
                      {CUSTOMER_TYPES_LABELS[key]}
                    </option>
                  )
                )}
              </Select>
            }
          />
        </div>
        <div className="w-1/3 ml-2">
          <InputLabel
            label={"External Id"}
            input={
              <Input
                disabled={disabled}
                placeholder="External Id"
                value={form.customers[0].external_id}
                onChange={(e) =>
                  setForm({
                    ...form,
                    customers: [
                      {
                        ...form.customers[0],
                        external_id: e.target.value,
                      },
                    ],
                  })
                }
              />
            }
          />
        </div>
        <div className="w-1/3 ml-2">
          {(form.customers[0].account_type === "S" ||
            form.customers[0].account_type === "U") && (
            <InputLabel
              label={"Account number"}
              input={
                <Input
                  disabled={disabled}
                  placeholder="Account number"
                  value={form.customers[0].account_number}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      customers: [
                        {
                          ...form.customers[0],
                          account_number: e.target.value,
                        },
                      ],
                    })
                  }
                />
              }
            />
          )}
        </div>
      </div>
      <InputLabel
        className="mt-4"
        label="Review groups"
        input={
          <SelectMultiple
            value={form.customers[0].review_groups
              .split(/,|;/g)
              .map((a) => a.trim())
              .filter((a) => a)}
            onChange={(e) =>
              setForm({
                ...form,
                customers: [
                  {
                    ...form.customers[0],
                    review_groups: _.uniq(e).join(","),
                  },
                ],
              })
            }
            options={_.uniq(reviewGroups).map((a) => ({ value: a, label: a }))}
          />
        }
      />
      <Info>
        You can create new review groups in the users management page by
        assigning new groups to an agent.
      </Info>
    </>
  );
};
