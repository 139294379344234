import { Info, ValueSecondary } from "@atoms/text";
import { KytTransaction } from "@features/kyt/types";
import { formatAmount } from "@features/utils/strings";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/solid";

export default function TransactionAmount(props: {
  transaction: KytTransaction;
}) {
  return (
    <div className="flex flex-row items-center space-x-2">
      <ValueSecondary>
        {formatAmount(props.transaction.amount, props.transaction.currency)}{" "}
        {props.transaction.currency}
      </ValueSecondary>
      {props.transaction.currency !== props.transaction.converted_currency && (
        <>
          <div className="rounded-full bg-slate-200 p-1">
            <ArrowsRightLeftIcon className="h-3 w-3 opacity-60" />
          </div>
          <Info>
            {formatAmount(
              props.transaction.converted_amount,
              props.transaction.converted_currency
            )}{" "}
            {props.transaction.converted_currency}
          </Info>
        </>
      )}
    </div>
  );
}
