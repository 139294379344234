import { Button } from "@atoms/button/button";
import { GetStartedCard } from "@atoms/card/get-started";
import { Page } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import { RiskFactorTable } from "@components/risk-factors-table";
import { RiskElementEditModalAtom } from "@components/risks/modals/metadata";
import { useHasAccess } from "@features/auth/state/use-access";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { ROUTES } from "@features/routes";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

export const RiskFactorsPage = () => {
  const { riskFactors: factors, loading } = useRiskFactors("customer");
  const edit = useSetRecoilState(RiskElementEditModalAtom("customer"));
  const navigate = useNavigate();
  const hasAccess = useHasAccess();

  const sortedRiskFactors = [...(factors || [])];
  sortedRiskFactors.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Page>
      {factors.length > 0 && !loading && hasAccess("RISK_SETTINGS_WRITE") && (
        <Button
          onClick={() => {
            edit({ open: true });
          }}
          className="float-right"
          theme="primary"
          size="sm"
        >
          Create risk factor
        </Button>
      )}
      <Title>Risk factors</Title>
      <div className="my-4">
        <GetStartedCard
          show={factors.length <= 0 && !loading}
          title={<>Risk factors</>}
          text={
            <>
              Your customers are scored based on risk factors. Risk factors are
              logic decision trees with a high level of customization.
              {hasAccess("RISK_SETTINGS_WRITE") && (
                <>
                  <br />
                  <Button
                    className="mt-4 block"
                    onClick={() => {
                      edit({ open: true });
                    }}
                    theme="primary"
                    size="lg"
                  >
                    Create risk factor
                  </Button>
                </>
              )}
            </>
          }
        />
      </div>
      <RiskFactorTable
        factors={factors}
        loading={loading}
        actions={(item) => (
          <>
            {hasAccess("RISK_SETTINGS_WRITE") && (
              <Button
                size="sm"
                theme="outlined"
                onClick={() => {
                  edit({ open: true, id: `${item.id}` });
                }}
              >
                Metadata
              </Button>
            )}
            <Button
              className="ml-2"
              size="sm"
              theme="primary"
              onClick={() => {
                navigate(
                  ROUTES.CustomerRiskFactorsDetails.replace(":id", `${item.id}`)
                );
              }}
            >
              Open tree
            </Button>
          </>
        )}
      />
    </Page>
  );
};
