import { useFetch } from "@features/utils";
import { RiskFactorElement, RiskResourceType, RiskTreeData } from "../types";

export class RiskApiClient {
  static getRiskBaseEndpoint(type: RiskResourceType, list?: boolean) {
    if (type === "scan") return "/web/scan/decision_tree";
    if (type === "press") return "/web/press/decision_tree";
    if (type === "kyt")
      return list ? "/web/kyt/decision_trees" : "/web/kyt/decision_tree";
    return `/web/${type === "customer" ? "" : type + "/"}risk_factors`;
  }

  static getRiskFactors = async (
    type: RiskResourceType
  ): Promise<RiskFactorElement[]> => {
    const response = await useFetch(`${this.getRiskBaseEndpoint(type, true)}`, {
      method: "GET",
    });
    if (response.status >= 300) {
      throw new Error("Unable to get risk factors");
    }
    const data = await response.json();

    if (type === "scan") {
      if (!data.tree_data) return [];
      return [
        {
          id: -1,
          group: "",
          label: "Scan Decision Tree",
          type: "customer",
          weight: 1,
          use_weight: true,
          tree_data: data.tree_data as RiskTreeData,
        },
      ];
    }

    if (type === "press") {
      if (!data.tree_data) return [];
      return [
        {
          id: -1,
          group: "",
          label: "Press Decision Tree",
          type: "customer",
          weight: 1,
          use_weight: true,
          tree_data: data.tree_data as RiskTreeData,
        },
      ];
    }

    return (data || []) as RiskFactorElement[];
  };

  static addRiskFactor = async (
    type: RiskResourceType,
    riskFactor: RiskFactorElement
  ) => {
    const response = await useFetch(`${this.getRiskBaseEndpoint(type)}`, {
      method: "POST",
      body: JSON.stringify(riskFactor),
    });
    return response;
  };

  static updateRiskFactor = async (
    type: RiskResourceType,
    riskFactor: RiskFactorElement
  ) => {
    if (!riskFactor.id || type === "scan" || type === "press")
      return RiskApiClient.addRiskFactor(type, riskFactor);
    const response = await useFetch(
      `${this.getRiskBaseEndpoint(type)}/${riskFactor.id}`,
      {
        method: "POST",
        body: JSON.stringify(riskFactor),
      }
    );
    if (response.status >= 300) {
      throw new Error("Unable to update risk factor");
    }
    return response;
  };

  static deleteRiskFactor = async (
    type: RiskResourceType,
    riskFactor: RiskFactorElement
  ) => {
    const response = await useFetch(
      `${this.getRiskBaseEndpoint(type)}${
        type === "scan" ? "" : `/${riskFactor.id}`
      }`,
      {
        method: "DELETE",
      }
    );
    return response;
  };
}
