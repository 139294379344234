import { Button } from "@atoms/button/button";
import { Checkbox } from "@atoms/input/input-checkbox";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Page } from "@atoms/layout/page";
import { Info, Title } from "@atoms/text";
import {
  AlertCategories,
  getActiveCategories,
} from "@components/alert-categories";
import { Table } from "@components/table";
import { WatchlistSources } from "@components/watchlist-sources";
import { WatchlistApiClient } from "@features/alerts-namesearch/api-client/api-client";
import { NameSearchFormAtom } from "@features/alerts-namesearch/state/store";
import {
  DowJonesRecordCategories,
  NameSearchOutput,
  NameSearchRequestOptions,
} from "@features/alerts-namesearch/types";
import { categoryToName, extractNames } from "@features/alerts/utils";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/react";
import jsPDF from "jspdf";
import _ from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ListProfileDetails } from "../shared/list-profile-details";
import { ProfileModalAtom } from "../shared/profile-modal";
import { NameSearchFilters } from "./filters";
import { NameSearchSummary, NameSearchSummaryType } from "./summary";
import { useAlertCategories } from "@features/alerts/state/use-alert-categories";

export const NameSearchPage = () => {
  const { categories } = useAlertCategories();
  const [filters, setFilters] = useRecoilState(NameSearchFormAtom);
  const [loading, setLoading] = useState(false);
  const [_results, setResults] = useState<NameSearchOutput[]>([]);
  const [quickCategFilter, setQuickCategFilter] = useState<string[]>([]);

  const results = _results.filter(
    (r) =>
      !quickCategFilter.length ||
      quickCategFilter.some((q) =>
        getActiveCategories({ isCategories: r as any }).includes(q)
      )
  );

  const [checkedForPdf, setCheckedForPdf] = useState<{
    [key: string]: boolean;
  }>({});
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [resultMetadata, setResultMetadata] = useState<NameSearchSummaryType>({
    total: 0,
    showed_total: 0,
    started: 0,
    ended: 0,
    duration: 0,
    next: null,
    filters: {},
  });
  const setProfileModal = useSetRecoilState(ProfileModalAtom);

  const loadMore = async () => {
    if (resultMetadata.next) {
      await search(
        resultMetadata.filters as NameSearchRequestOptions,
        resultMetadata.next
      );
    }
  };

  const search = async (
    filters: NameSearchRequestOptions,
    pageToken?: string
  ) => {
    setLoading(true);
    const fixedFilters = JSON.stringify(filters);
    const started = Date.now();
    const res = await WatchlistApiClient.search(
      pageToken
        ? {
            ...(resultMetadata?.filters as NameSearchRequestOptions),
            page_token: pageToken,
          }
        : filters
    );
    if (res?.results?.length !== undefined) {
      if (pageToken) {
        setResults([...results, ..._.sortBy(res?.results, (a) => -a.score)]);
        setResultMetadata({
          ...resultMetadata,
          duration: 0,
          ended: Date.now(),
          total: res?.total,
          showed_total: resultMetadata.showed_total + res?.results.length,
          next: res?.next_page_token || null,
        });
      } else {
        setResults(_.sortBy(res?.results, (a) => -a.score));
        setResultMetadata({
          duration: Date.now() - started,
          started,
          ended: Date.now(),
          total: res?.total,
          showed_total: res?.results.length,
          next: res?.next_page_token || null,
          filters: JSON.parse(fixedFilters),
        });
      }
      const checkAll: any = {};
      for (const r of [
        ...results,
        ..._.sortBy(res?.results, (a) => -a.score),
      ]) {
        if (r.over_threshold) checkAll[r.list_profile.list_id] = true;
      }
      setCheckedForPdf(checkAll);
    } else {
      Sentry.captureException(
        new Error("Name search failed got result " + JSON.stringify(res))
      );
      toast.error("An error occurred while searching.");
    }
    setLoading(false);
  };

  return (
    <Page>
      <Title>Name search</Title>

      <NameSearchFilters
        loading={loading}
        value={filters}
        onChange={(v) => {
          setFilters({ ...filters, ...v });
          search({ ...filters, ...v });
        }}
      />

      <div className="float-right flex items-center flex-row mt-4">
        <Button
          theme="default"
          size="sm"
          onClick={() => {
            if (
              Object.values(checkedForPdf).filter(Boolean).length ===
              results.length
            ) {
              setCheckedForPdf({});
            } else {
              const checkAll: any = {};
              for (const r of results) {
                checkAll[r.list_profile.list_id] = true;
              }
              setCheckedForPdf(checkAll);
            }
          }}
        >
          {Object.values(checkedForPdf).filter(Boolean).length !==
          results.length
            ? "Check all"
            : "Uncheck all"}
        </Button>

        <Button
          theme="default"
          className="ml-2"
          loading={loadingPdf}
          onClick={() => {
            setLoadingPdf(true);
            setTimeout(async () => {
              const generator = new jsPDF();
              await new Promise((r) => {
                generator.html(document.getElementById("pdf")!, {
                  callback: function (pdf) {
                    pdf.save(
                      "namesearch-" +
                        new Date(resultMetadata.ended).toISOString() +
                        ".pdf"
                    );
                    r(true);
                  },
                  x: 15,
                  y: 15,
                  width: 170,
                  windowWidth: 1000,
                });
              });
              setLoadingPdf(false);
            }, 2000);
          }}
        >
          <ArrowDownTrayIcon className="h-4 w-4 mr-2" /> PDF
        </Button>

        <div className="w-40 ml-6">
          <SelectMultiple
            placeholder="Show only..."
            options={_.sortBy(
              Object.keys(categories).filter((c) => categories[c] !== "rca"),
              (c) => (categories[c] || "").replace("rca_", "zzz_")
            ).map((c) => ({
              value: categories[c],
              label: categoryToName[categories[c]] || categories[c],
            }))}
            value={quickCategFilter}
            onChange={(v) => {
              setQuickCategFilter(v);
            }}
          />
        </div>
      </div>

      {loadingPdf && (
        <PrintableVersion
          results={results}
          checkedForPdf={checkedForPdf}
          resultMetadata={resultMetadata}
        />
      )}

      <div className="my-4" />

      <Table
        onClick={(row) => {
          setProfileModal({
            open: true,
            id: row.list_profile.list_id,
            record: row.list_profile,
            associates: row.associates,
            categories: row as unknown as DowJonesRecordCategories,
          });
        }}
        loading={loading}
        data={results || []}
        showPagination={false}
        cellClassName={(row) => (row.over_threshold ? "" : "opacity-50")}
        columns={[
          {
            title: "PDF",
            thClassName: "w-12",
            render: (row) => (
              <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  value={checkedForPdf[row.list_profile.list_id]}
                  onChange={(s) =>
                    setCheckedForPdf({
                      ...checkedForPdf,
                      [row.list_profile.list_id]: s,
                    })
                  }
                />
              </div>
            ),
          },
          {
            title: "#",
            thClassName: "w-24",
            render: (row) => <Info>{row.list_profile.list_id}</Info>,
          },
          {
            title: "Name",
            render: (row) =>
              [
                extractNames(row.list_profile, "first_name", "Primary Name"),
                extractNames(row.list_profile, "surname", "Primary Name"),
                extractNames(row.list_profile, "entity_name", "Primary Name"),
              ]
                .filter((a) => a.trim())
                .join(" "),
          },
          {
            title: "",
            thClassName: "w-40",
            render: (row) => (
              <div className="opacity-50">
                <WatchlistSources sources={row.list_types} />
              </div>
            ),
          },
          {
            title: "Categories",
            thClassName: "w-40",
            render: (row) => <AlertCategories isCategories={row as any} />,
          },
          {
            title: "Score",
            thClassName: "w-20",
            render: (row) => (
              <Info
                className={row.over_threshold ? "" : "text-red-500 opacity-50"}
              >
                {row.score}%
              </Info>
            ),
          },
        ]}
      />

      <div className="my-4" />

      {resultMetadata.next && (
        <div className="text-center">
          <Button
            className="mb-4"
            theme="default"
            loading={loading}
            onClick={() => {
              loadMore();
            }}
          >
            Load more
          </Button>
        </div>
      )}

      {!!resultMetadata.ended && <NameSearchSummary summary={resultMetadata} />}
    </Page>
  );
};

export const PrintableVersion = ({
  resultMetadata,
  results,
  checkedForPdf,
}: {
  resultMetadata: NameSearchSummaryType;
  results: NameSearchOutput[];
  checkedForPdf: {
    [key: string]: boolean;
  };
}) => {
  return (
    <div className="hidden">
      <div id="pdf" style={{ width: 1000 }}>
        <div className="pdf-page">
          <Title>Name Search result from Algoreg</Title>
          <hr style={{ marginTop: 24, marginBottom: 16 }} />
          {!!resultMetadata.ended && (
            <NameSearchSummary summary={resultMetadata} />
          )}
        </div>

        {results
          .filter((r) => checkedForPdf[r.list_profile.list_id])
          .map((doc) => {
            return (
              <div
                key={doc.list_profile.list_id}
                className="pdf-page"
                style={{ marginTop: 40 } as any}
              >
                <Title>Result #{doc.list_profile.list_id}</Title>
                <hr style={{ marginTop: 24, marginBottom: 16 }} />

                <ListProfileDetails
                  record={doc.list_profile}
                  associates={doc.associates}
                  categories={doc as any}
                  printable
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
