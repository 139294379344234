import { Tag } from "@atoms/badge/tag";
import { PageBlockHr } from "@atoms/layout/page-block";
import { Base, Info, SectionSmall } from "@atoms/text";
import { ModalImage } from "@components/modal-image";
import Env from "@config/environment";
import { SessionAlertType } from "@features/sessions/types";
import { flatMapControls } from "@features/sessions/utils";
import { twMerge } from "tailwind-merge";
import { AlertTag } from "./alert-tag";
import { Controls } from "./controls";
import { SessionBlock } from "./session-block";

export default function IdentityDocumentReview({
  anonymized,
  ...props
}: {
  alert: SessionAlertType;
  anonymized: boolean;
}) {
  const flatMappedControls = flatMapControls(props.alert.controls || []);

  return (
    <SessionBlock
      id="identity-question"
      title="Identity Document"
      subtitle="Identity Document verification and extraction"
      suffix={<AlertTag state={props.alert.state} />}
      tries={props.alert.question?.answers?.videos?.length}
      render={(tryIndex) => (
        <>
          {props.alert.score !== undefined && (
            <>
              {props.alert.question?.answers?.videos[tryIndex as number] &&
                !anonymized && (
                  <>
                    <div className="flex flex-wrap gap-4 mt-4 justify-center">
                      <video
                        className="bg-black h-72 w-auto object-cover rounded-md border border-slate-300"
                        src={`${Env.server}${
                          props.alert.question?.answers?.videos[
                            tryIndex as number
                          ].url
                        }`}
                        controls
                        preload="auto"
                      />
                    </div>
                  </>
                )}
              <PageBlockHr />
              <SectionSmall>Controls</SectionSmall>
              <Controls controls={flatMappedControls} />
              <PageBlockHr />
              <div className="flex flex-col space-y-2">
                <SectionSmall>Customer Data matching</SectionSmall>
                <Info>
                  Comparison of customer given information and identity document
                  information.
                </Info>
                <div className="flex flex-row space-x-4 py-2">
                  <ScoreCard
                    name="First Name"
                    score={props.alert.intermediate_scoring?.first_name || 0}
                  />
                  <ScoreCard
                    name="Last Name"
                    score={props.alert.intermediate_scoring?.last_name || 0}
                  />
                  <ScoreCard
                    name="Date of Birth"
                    score={props.alert.intermediate_scoring?.date_of_birth || 0}
                  />
                  {props.alert.intermediate_scoring?.nationality !==
                    undefined && (
                    <ScoreCard
                      name="Nationality"
                      score={props.alert.intermediate_scoring?.nationality || 0}
                    />
                  )}
                </div>
              </div>
              {!anonymized && (
                <>
                  <PageBlockHr />
                  <div className="flex flex-col space-y-2">
                    <SectionSmall>Identity Documents</SectionSmall>
                    <Info>Document image taken during session.</Info>
                    <div className="flex flex-row space-x-2 overflow-x-auto py-2">
                      {(props.alert.question?.answers?.attachment_urls
                        ?.length ?? 0) === 0 && (
                        <Info>No identity document found</Info>
                      )}
                      {props.alert.question?.answers?.attachment_urls?.map(
                        (url) => (
                          <div key={url.id}>
                            <ModalImage
                              className="w-auto h-32 object-cover rounded-md border border-slate-300"
                              small={`${Env.server}/api/v1/downloads/${url.id}`}
                              large={`${Env.server}/api/v1/downloads/${url.id}`}
                              alt={url.type}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <PageBlockHr />
                  <div className="flex flex-col space-y-2">
                    <SectionSmall>Document Details</SectionSmall>
                    <Info>
                      These document details have been extracted automatically
                      by our systems. They might not perfectly reflect the
                      original document.
                    </Info>
                    <div className="flex flex-col space-y-2 py-2">
                      <div className="flex flex-row space-x-2">
                        <div className="w-1/3 flex flex-col">
                          <Base>First Name</Base>
                          <Info>
                            {(props.alert.identity_content?.holder_detail
                              ?.first_name.length ?? 0) > 0
                              ? props.alert.identity_content?.holder_detail.first_name.join(
                                  " "
                                )
                              : "-"}
                          </Info>
                        </div>
                        <div className="w-1/3 flex flex-col">
                          <Base>Last Name</Base>
                          <Info>
                            {(props.alert.identity_content?.holder_detail
                              ?.last_name.length ?? 0) > 0
                              ? props.alert.identity_content?.holder_detail.last_name.join(
                                  " "
                                )
                              : "-"}
                          </Info>
                        </div>
                      </div>
                      <div className="flex flex-row space-x-2">
                        <div className="w-1/3 flex flex-col">
                          <Base>Birth Date</Base>
                          <Info>
                            {props.alert.identity_content?.holder_detail
                              .birth_date || "-"}
                          </Info>
                        </div>
                        <div className="w-1/3 flex flex-col">
                          <Base>Gender</Base>
                          <Info>
                            {props.alert.identity_content?.holder_detail
                              .gender ?? "-"}
                          </Info>
                        </div>
                      </div>
                      <div className="flex flex-row space-x-2">
                        <div className="w-1/3 flex flex-col">
                          <Base>Identity Number</Base>
                          <Info>
                            {props.alert.identity_content?.document_detail
                              .document_number || "-"}
                          </Info>
                        </div>
                        <div className="w-1/3 flex flex-col">
                          <Base>Nationality</Base>
                          <Info>
                            {props.alert.identity_content?.holder_detail
                              .nationality || "-"}
                          </Info>
                        </div>
                        <div className="grow flex flex-col">
                          <Base>Issuance country</Base>
                          <Info>
                            {props.alert.identity_content?.document_detail
                              .emit_country || "-"}
                          </Info>
                        </div>
                      </div>
                      <div className="flex flex-row space-x-2">
                        <div className="w-1/3 flex flex-col">
                          <Base>Issuance Date</Base>
                          <Info>
                            {props.alert.identity_content?.document_detail
                              .emit_date || "-"}
                          </Info>
                        </div>
                        <div className="w-1/3 flex flex-col">
                          <Base>Expiry Date</Base>
                          <Info>
                            {props.alert.identity_content?.document_detail
                              .expiration_date || "-"}
                          </Info>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <Base>MRZ</Base>
                        {(props.alert.identity_content?.mrz?.length ?? 0) ===
                        0 ? (
                          <Info>No MRZ found</Info>
                        ) : (
                          <Info className="font-mono">
                            {(props.alert.identity_content?.mrz ?? []).map(
                              (a) => (
                                <div key={a}>{a}</div>
                              )
                            )}
                          </Info>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    />
  );
}

const ScoreCard = ({ score, name }: { score: number; name: string }) => {
  return (
    <div
      className={twMerge(
        "w-1/4 flex flex-col bg-slate-50 rounded p-2 border border-slate-100 space-y-2 grow",
        score <= 75 && "bg-red-100"
      )}
    >
      <div className="flex flex-row justify-between">
        <Base>{name}</Base>
        <Tag>{Math.floor(score || 0) ?? 0}%</Tag>
      </div>
      <div
        className={twMerge(
          "bg-slate-200 h-2 overflow-hidden rounded",
          score <= 75 && "bg-red-200"
        )}
      >
        <div
          className={twMerge(
            "h-full",
            score > 95 && "bg-green-400",
            score > 75 && score <= 95 && "bg-orange-400",
            score <= 75 && "bg-red-400"
          )}
          style={{
            width: `${score ?? 0}%`,
          }}
        ></div>
      </div>
    </div>
  );
};
